import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes";

//lightning checks
import mainlogo from "../../assets/img/mainlogo.png";
//bm2bank
// import mainlogo from '../../assets/img/Logo-2.png'
//bm2pay
// import mainlogo from '../../assets/img/bm2pay.svg'
import { useParams } from "react-router-dom";
import { Modal, ModalContent,ModalBody, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import { useContext } from "react";
import { ClientContext } from "ClientProvider";

// bm2pay.svg

const Sidebar = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, onClose } = props;
  const clientId = localStorage.getItem("clietnIds");
    const {
      SettlementAPISttus,
      setisOpenSettlementAPISttus,
      isOpenSettlementAPISttus,
      setSettlementAPISttus,
    } = useContext(ClientContext);
  const updatedRoutes = routes.map((route) => {
    if (route.name === "Smart Routing") {
      return {
        ...route,
        isHidden: clientId === null ? true : false,
        hideInSidebar: clientId === null ? true : false,
      };
    }

    return route;
  });
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>
      {/*lightning*/}
      <div
        className={`ml-[30px] mr-[40px] mt-[47px] flex items-center justify-center`}
      >
        {/* bm2pay */}
        {/* <div
        className={`ml-[30px] mr-[40px] mt-[30px] flex items-center justify-center`}
      > */}
        {/* {lightning} */}
        <img
          src={mainlogo}
          className="h-full w-10 object-cover"
          alt="main-logo"
        />
        {/* {bm2pay} */}
        {/* <img
          src={mainlogo}
          className="h-full w-20 object-cover"
          alt="main-logo"
        /> */}
        <div className="flex flex-col  font-poppins text-[20px] font-bold uppercase text-navy-700 dark:text-white">
          lightning
          <span className="text-sm font-light">checks</span>
        </div>
      </div>
      {/*lightning*/}
      <div className="mb-7 mt-[50px] h-px bg-gray-300 dark:bg-white/30" />
      {/* {bm2pay} */}
      {/* <div className="mb-7 mt-[38px] h-px bg-gray-300 dark:bg-white/30" /> */}
      {/* Nav item */}

      <ul
        onMouseEnter={() => {
          if (SettlementAPISttus == "in-progress") {
            setisOpenSettlementAPISttus(true);
          } else {
            setisOpenSettlementAPISttus(false);
          }
        }}
        className="mb-auto max-h-[75vh] overflow-auto pt-1 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1"
      >
        <Links
          routes={updatedRoutes.filter((data) => data.layout === "/admin")}
        />
      </ul>
      <Modal isOpen={isOpenSettlementAPISttus} onClose={() => {}}>
        <ModalOverlay className="bg-[#000] !opacity-30" />

        <ModalContent className="sm:max-w-xxl scrollbarhide z-[1002]  !m-auto flex max-h-[100vh] min-h-[85vh] max-w-[800px] justify-start   overflow-auto !p-3 sm:my-8 sm:w-full ">
          <ModalBody>
            <Card extra=" max-w-[800px] sm:w-full flex flex-col justify-start !z-[1004]    scrollbarhide overflow-auto">
              <h5 className="p-5 px-[30px] text-2xl font-bold">
                Settlemnet Report is being created.
              </h5>
              <div className="mt-5 flex gap-2 px-[30px] pb-[30px]">
                <button
                  onClick={() => {
                    setisOpenSettlementAPISttus(false);
                  }}
                  className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Close
                </button>

                <button
                  onClick={() => {
                    setisOpenSettlementAPISttus(false);
                  }}
                  className="linear rounded-xl bg-indigo-50 px-5 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-indigo-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                >
                  <a target="_blank" href="/">
                    Open in New TAB
                  </a>
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
