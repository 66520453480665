import moment from 'moment';
export default function declinedReportExcelSheets(jsonData: any, workbook: any, templateColor: any) {
    try{
        let reportData = jsonData.excelData.filter((sheetData: any)=>sheetData.sheet_type == "DECLINED" && sheetData?.sheetname?.includes('DECLINED') && sheetData?.sheet_generation == true)
        // console.log("declined reportdata :: ", {reportData});
    
        reportData?.map((sheets: any, index: number) => {
            // Declined
            // if (sheets?.sheet_type == 'DECLINED' && sheets?.sheetname?.includes('DECLINED') && sheets?.sheetdata && sheets?.sheet_generation == true) {
    
    
                const worksheet = workbook?.addWorksheet(`${sheets?.sheetname}`);
            
                // column beautification
                worksheet.getColumn('B').width = 14.18;
                worksheet.getColumn('C').width = 40.18;
                worksheet.getColumn('D').width = 40;
                worksheet.getColumn('E').width = 41.27;
                worksheet.getColumn('F').width = 17.82;
                worksheet.getColumn('G').width = 27.64;
                worksheet.getColumn('H').width = 16.18;
                worksheet.getColumn('I').width = 13.82;
                worksheet.getColumn('J').width = 20.27;
                worksheet.getColumn('K').width = 11.82;
                worksheet.getColumn('L').width = 13.45;
                worksheet.getColumn('M').width = 16.09;
                worksheet.getColumn('N').width = 21.82;
                // worksheet.getColumn('O').width = 14.82;
    
                // merge cells for summary heading
                worksheet.mergeCells('A2:N2');
                worksheet.getCell('A2').value = 'Summary';
                worksheet.getCell('A2').font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('A2').fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.yellow }
                };
    
                // add empty row
                worksheet.addRow([])
    
    
                let cardSummaryHeader = worksheet?.lastRow?.number + 1;
    
                // multiple headers with data with cell of blue color fill
    
                //  # of transactions
                worksheet.mergeCells(`A${cardSummaryHeader}:G${cardSummaryHeader}`);
                worksheet.getCell(`A${cardSummaryHeader}`).value = "# of Transactions";
                worksheet.getCell(`A${cardSummaryHeader}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`A${cardSummaryHeader}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };
    
                //  Amount
                worksheet.mergeCells(`H${cardSummaryHeader}:J${cardSummaryHeader}`);
                worksheet.getCell(`H${cardSummaryHeader}`).value = "Amount";
                worksheet.getCell(`H${cardSummaryHeader}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`H${cardSummaryHeader}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };
    
                //  % Fee
                worksheet.mergeCells(`K${cardSummaryHeader}`);
                worksheet.getCell(`K${cardSummaryHeader}`).value = "% Fee";
                worksheet.getCell(`K${cardSummaryHeader}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`K${cardSummaryHeader}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };
    
                //  $ Fee
                worksheet.mergeCells(`L${cardSummaryHeader}`);
                worksheet.getCell(`L${cardSummaryHeader}`).value = "$ Fee";
                worksheet.getCell(`L${cardSummaryHeader}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`L${cardSummaryHeader}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };
    
                //  Fee Amount
                worksheet.mergeCells(`M${cardSummaryHeader}`);
                worksheet.getCell(`M${cardSummaryHeader}`).value = "Fee Amount";
                worksheet.getCell(`M${cardSummaryHeader}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`M${cardSummaryHeader}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };
    
                //  Net Amount
                worksheet.mergeCells(`N${cardSummaryHeader}`);
                worksheet.getCell(`N${cardSummaryHeader}`).value = "Net Amount";
                worksheet.getCell(`N${cardSummaryHeader}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`N${cardSummaryHeader}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };
    
                let cardSummaryHeaderValue = worksheet?.lastRow?.number + 1;
    
                // value of # of transactions
                worksheet.mergeCells(`A${cardSummaryHeaderValue}:G${cardSummaryHeaderValue}`);
                worksheet.getCell(`A${cardSummaryHeaderValue}`).value = `${sheets?.sheetdata?.total[1]?.all_client_total?.total_client_transactions.toFixed(2) ?? ""}`;
                worksheet.getCell(`A${cardSummaryHeaderValue}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`A${cardSummaryHeaderValue}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };
    
                // value of Amount
                worksheet.mergeCells(`H${cardSummaryHeaderValue}:J${cardSummaryHeaderValue}`);
                worksheet.getCell(`H${cardSummaryHeaderValue}`).value = `${sheets?.sheetdata?.total[1]?.all_client_total?.all_client_total_amount.toFixed(2) ?? ""}`;
                worksheet.getCell(`H${cardSummaryHeaderValue}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`H${cardSummaryHeaderValue}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };
    
                // value of % Fee
                worksheet.mergeCells(`K${cardSummaryHeaderValue}`);
                worksheet.getCell(`K${cardSummaryHeaderValue}`).value = `${sheets?.sheetdata?.total[1]?.all_client_total?.all_client_total_percent_fee.toFixed(2) ?? ""}`;
                worksheet.getCell(`K${cardSummaryHeaderValue}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`K${cardSummaryHeaderValue}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };
    
                // value of $ Fee
                worksheet.mergeCells(`L${cardSummaryHeaderValue}`);
                worksheet.getCell(`L${cardSummaryHeaderValue}`).value = `${sheets?.sheetdata?.total[1]?.all_client_total?.all_client_total_fixed_fee.toFixed(2) ?? ""}`;
                worksheet.getCell(`L${cardSummaryHeaderValue}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`L${cardSummaryHeaderValue}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };
    
                // value of Fee Amount
                worksheet.mergeCells(`M${cardSummaryHeaderValue}`);
                worksheet.getCell(`M${cardSummaryHeaderValue}`).value = `${sheets?.sheetdata?.total[1]?.all_client_total?.all_client_total_fee_amount.toFixed(2) ?? ""}`;
                worksheet.getCell(`M${cardSummaryHeaderValue}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`M${cardSummaryHeaderValue}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };
    
                // value of Net Amount
                worksheet.mergeCells(`N${cardSummaryHeaderValue}`);
                worksheet.getCell(`N${cardSummaryHeaderValue}`).value = `${sheets?.sheetdata?.total[1]?.all_client_total?.all_client_total_net_amount.toFixed(2) ?? ""}`;
                worksheet.getCell(`N${cardSummaryHeaderValue}`).font = { bold: true, color: { argb: templateColor?.white } };
                worksheet.getCell(`N${cardSummaryHeaderValue}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: templateColor?.blue }
                };
    
                // add empty row
                worksheet.addRow([])
    
                Object?.entries(sheets?.sheetdata)?.forEach(([key, value]: [string, any]) => {
                    if (Array?.isArray(value) && key != 'total' && value?.length > 0) {
    
    
                        let clientDetailsRow = worksheet?.lastRow?.number + 1;
    
                        worksheet.mergeCells(`A${clientDetailsRow}:N${clientDetailsRow}`);
                        // let clientName = ((sheets?.sheetdata?.total as { [key: string]: any }[])[0][key]??.client_name || '');
                        let clientName = (sheets?.sheetdata?.total[0][key]?.client_name || '');
                        worksheet.getCell(`A${clientDetailsRow}`).value = clientName;
                        worksheet.getCell(`A${clientDetailsRow}`).font = { bold: true, color: { argb: templateColor?.white } };
                        worksheet.getCell(`A${clientDetailsRow}`).alignment = { vertical: 'middle', horizontal: 'center' };
                        worksheet.getCell(`A${clientDetailsRow}`).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: templateColor?.yellow }
                        };
    
                        // adding empty row
                        worksheet.addRow([])
    
                        let lastRowForDataHeaderSummary = worksheet?.lastRow?.number + 1;
    
                        // #
                        worksheet.getCell(`A${lastRowForDataHeaderSummary}`).value = "";
                        worksheet.getCell(`A${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`A${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // Merchant
                        worksheet.getCell(`B${lastRowForDataHeaderSummary}`).value = "Merchant";
                        worksheet.getCell(`B${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`B${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // ID
                        worksheet.getCell(
                          `C${lastRowForDataHeaderSummary}`
                        ).value = "Internal Id";
                        worksheet.getCell(`C${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`C${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // // Gateway id
                        // worksheet.getCell(`D${lastRowForDataHeaderSummary}`).value = "Gateway id";
                        // worksheet.getCell(`D${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        // worksheet.getCell(`D${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // ET_ID
                        worksheet.getCell(
                          `D${lastRowForDataHeaderSummary}`
                        ).value = "Transaction Id";
                        worksheet.getCell(`D${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`D${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // // AUTH
                        // worksheet.getCell(`F${lastRowForDataHeaderSummary}`).value = "AUTH";
                        // worksheet.getCell(`F${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        // worksheet.getCell(`F${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // DATE
                        worksheet.getCell(`E${lastRowForDataHeaderSummary}`).value = "Date";
                        worksheet.getCell(`E${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`E${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // AMOUNT
                        worksheet.getCell(`F${lastRowForDataHeaderSummary}`).value = "Amount";
                        worksheet.getCell(`F${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`F${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // CURRENCY
                        worksheet.getCell(`G${lastRowForDataHeaderSummary}`).value = "Currency";
                        worksheet.getCell(`G${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`G${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // BRAND
                        worksheet.getCell(`H${lastRowForDataHeaderSummary}`).value = "Brand";
                        worksheet.getCell(`H${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`H${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // BIN
                        worksheet.getCell(`I${lastRowForDataHeaderSummary}`).value = "Bin";
                        worksheet.getCell(`I${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`I${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // Last 4
                        worksheet.getCell(`J${lastRowForDataHeaderSummary}`).value = "Last 4";
                        worksheet.getCell(`J${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`J${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // % FEE
                        worksheet.getCell(`K${lastRowForDataHeaderSummary}`).value = "% Fee";
                        worksheet.getCell(`K${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`K${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // $ FEE
                        worksheet.getCell(`L${lastRowForDataHeaderSummary}`).value = "$ Fee";
                        worksheet.getCell(`L${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`L${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // FEE AMOUNT
                        worksheet.getCell(`M${lastRowForDataHeaderSummary}`).value = "Fee Amount";
                        worksheet.getCell(`M${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`M${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // Reserve
                        // worksheet.getCell(`N${lastRowForDataHeaderSummary}`).value = "Reserve";
                        // worksheet.getCell(`N${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        // worksheet.getCell(`N${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // NET AMOUNT
                        worksheet.getCell(`N${lastRowForDataHeaderSummary}`).value = "Net Amount";
                        worksheet.getCell(`N${lastRowForDataHeaderSummary}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`N${lastRowForDataHeaderSummary}`).font = { bold: true, color: { argb: templateColor?.white } }
    
                        value?.forEach((clientTransactionData: any, clientTransactionDataIndex: number) => {
    
                            // values of #
                            worksheet.getCell(`A${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionDataIndex + 1}`;
                            // worksheet.getCell(`A${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`A${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of Merchant
                            worksheet.getCell(`B${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.Merchant ?? ""}`;
                            // worksheet.getCell(`B${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`B${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of ID
                            worksheet.getCell(`C${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.id ?? ""}`;
                            // worksheet.getCell(`C${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`C${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // // values of Gateway id
                            // worksheet.getCell(`D${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.gateway_id ?? ""}`;
                            // worksheet.getCell(`D${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            // worksheet.getCell(`D${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of ET_ID
                            worksheet.getCell(`D${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.et_id ?? ""}`;
                            // worksheet.getCell(`D${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`D${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // // values of AUTH
                            // worksheet.getCell(`F${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.auth ?? ""}`;
                            // worksheet.getCell(`F${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            // worksheet.getCell(`F${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of DATE
                            worksheet.getCell(`E${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.date ?? ""}`;
                            // worksheet.getCell(`E${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`E${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of AMOUNT
                            worksheet.getCell(`F${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.amount?.toFixed(2) ?? ""}`;
                            // worksheet.getCell(`F${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`F${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of CURRENCY
                            worksheet.getCell(`G${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.currency ?? ""}`;
                            // worksheet.getCell(`G${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`G${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of BRAND
                            worksheet.getCell(`H${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.card_type ?? ""}`;
                            // worksheet.getCell(`H${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`H${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of BIN
                            worksheet.getCell(`I${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.bin ?? ""}`;
                            // worksheet.getCell(`I${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`I${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of Last 4
                            worksheet.getCell(`J${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.last_4 ?? ""}`;
                            // worksheet.getCell(`J${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`J${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of % FEE
                            worksheet.getCell(`K${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.percent_fees ?? ""}`;
                            // worksheet.getCell(`K${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`K${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of $ FEE
                            worksheet.getCell(`L${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData.fixed_fees?.toFixed(2) ?? ""}`;
                            // worksheet.getCell(`L${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`L${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of FEE AMOUNT
                            worksheet.getCell(`M${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.fee_amount.toFixed(2) ?? ""}`;
                            // worksheet.getCell(`M${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`M${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                            // values of NET AMOUNT
                            worksheet.getCell(`N${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).value = `${clientTransactionData?.net_amount.toFixed(2) ?? ""}`;
                            // worksheet.getCell(`N${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                            worksheet.getCell(`N${lastRowForDataHeaderSummary + clientTransactionDataIndex + 1}`).font = { color: { argb: templateColor?.black } }
                        }
    
                        )
    
                        // add empty row
                        worksheet.addRow([])
    
    
                        // calculations
                        let lastRowForDataCalculations = worksheet?.lastRow?.number + 1;
    
                        // #
                        worksheet.getCell(`A${lastRowForDataCalculations}`).value = `${sheets?.sheetdata?.total[0][key]?.Total?.total_transactions.toFixed(2) ?? ""}`;
                        worksheet.getCell(`A${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`A${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // Merchant
                        worksheet.getCell(`B${lastRowForDataCalculations}`).value = '';
                        worksheet.getCell(`B${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`B${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // ID
                        worksheet.getCell(`C${lastRowForDataCalculations}`).value = "";
                        worksheet.getCell(`C${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`C${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // // Gateway id
                        // worksheet.getCell(`D${lastRowForDataCalculations}`).value = "";
                        // worksheet.getCell(`D${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        // worksheet.getCell(`D${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // ET_ID
                        worksheet.getCell(`D${lastRowForDataCalculations}`).value = "";
                        worksheet.getCell(`D${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`D${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // // AUTH
                        // worksheet.getCell(`F${lastRowForDataCalculations}`).value = "";
                        // worksheet.getCell(`F${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        // worksheet.getCell(`F${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // DATE
                        worksheet.getCell(`E${lastRowForDataCalculations}`).value = "";
                        worksheet.getCell(`E${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`E${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // AMOUNT
                        worksheet.getCell(`F${lastRowForDataCalculations}`).value = `${sheets?.sheetdata?.total[0][key]?.Total?.client_total_amount.toFixed(2) ?? ""}`;
                        worksheet.getCell(`F${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`F${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // CURRENCY
                        worksheet.getCell(`G${lastRowForDataCalculations}`).value = "";
                        worksheet.getCell(`G${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`G${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // BRAND
                        worksheet.getCell(`H${lastRowForDataCalculations}`).value = "";
                        worksheet.getCell(`H${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`H${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // BIN
                        worksheet.getCell(`I${lastRowForDataCalculations}`).value = "";
                        worksheet.getCell(`I${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`I${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // Last 4
                        worksheet.getCell(`J${lastRowForDataCalculations}`).value = "";
                        worksheet.getCell(`J${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`J${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // % FEE
                        worksheet.getCell(`K${lastRowForDataCalculations}`).value = `${sheets?.sheetdata?.total[0][key]?.Total?.client_total_percent_fee.toFixed(2) ?? ""}`;
                        worksheet.getCell(`K${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`K${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // $ FEE
                        worksheet.getCell(`L${lastRowForDataCalculations}`).value = `${sheets?.sheetdata?.total[0][key]?.Total?.client_total_fixed_fee.toFixed(2) ?? ""}`;
                        worksheet.getCell(`L${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`L${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // FEE AMOUNT
                        worksheet.getCell(`M${lastRowForDataCalculations}`).value = `${sheets?.sheetdata?.total[0][key]?.Total?.client_total_fee_amount.toFixed(2) ?? ""}`;
                        worksheet.getCell(`M${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`M${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
                        // NET AMOUNT
                        worksheet.getCell(`N${lastRowForDataCalculations}`).value = `${sheets?.sheetdata?.total[0][key]?.Total?.client_total_net_amount.toFixed(2) ?? ""}`;
                        worksheet.getCell(`N${lastRowForDataCalculations}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: templateColor?.blue } }
                        worksheet.getCell(`N${lastRowForDataCalculations}`).font = { bold: true, color: { argb: templateColor?.white } }
    
    
                        // add empty row
                        worksheet.addRow([])
                    }
                });
    
            // }
        })
    }catch(error){
        // console.log("error in declined report", error)
    }

}