import { BsPlusCircle } from "react-icons/bs";
import Card from "components/card";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import { Select as AntSelect } from "antd";
import Checkbox from "components/checkbox";
import DateRange from "components/fields/DateRange";
import { useContext, useEffect, useState } from "react";
import { getClientDetails } from "api/dashboard";
import toast from "react-hot-toast";
import { currencyCodes, getCurrencySymbol } from "utils/currencyList";
import moment from "moment";
import {
  generateSettlementReport,
  getClientPaymentMethodDetailsSettlement,
  updateSettlementRecord,
} from "api/settlement";
import { getUserId } from "utils/auth";
import { MdEdit } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DivLoader from "components/divloader/DivLoader";
import { ClientContext } from "ClientProvider";
function CrerateSettlementReport(props: {
  allOrgDetails: any;
  fetchAllsettlement: () => void;
  info?: any;
}) {
  const { allOrgDetails, fetchAllsettlement, info } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectOrg, setSelectOrg] = useState<any>();
  const [selectCurrency, setSelectCurrency] = useState<any>("USDT");
  const [allClientDetails, setAllClientDetails] = useState<any>([]);
  const [selectClients, setSelectClients] = useState<any>([]);
  const [selectStatus, setSelectStatus] = useState<any>([]);
  const [clientPaymentMethod, setClientPaymentMethod] = useState<any>([]);
  const [clientCardType, setClientCardType] = useState<any>([]);
  const [selectPaymentMethod, setSelectPaymentMethod] = useState<any>([]);
  const [formValuesErr, setFormValuesErr] = useState<any>([]);
  const { setSettlementAPISttus } = useContext(ClientContext);
  const handleClose = () => {
    onClose();
    setSelectClients([]);
    setSelectOrg("");
    setSelectCurrency("USDT");
    setAllClientDetails([]);
    setSelectClients([]);
    setSelectStatus([]);
    setClientPaymentMethod([]);
    setClientCardType([]);
    setSelectPaymentMethod([]);
  };
  const updateSettlementRecordFun = (data: any) => {
    let fData: any = {
      ...data,
      id: info?.row?.original?.id,
    };
    setSettlementAPISttus("in-progress");
    onClose();
    updateSettlementRecord(fData)
      .then((res) => {
        toast.success("Report updated successfully!");
        fetchAllsettlement();
      })
      .catch((error) => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        handleClose();
        setSettlementAPISttus("none");
      });
  };

  const checkFilterData = () => {
    let isOkay = false;
    if (selectOrg && selectClients?.length > 0 && selectCurrency) {
      isOkay = true;
    }
    return isOkay;
  };

  const fetchClientData = () => {
    let data = {
      org_ids: selectOrg,
    };
    getClientDetails(data)
      .then((res) => {
        let setData = [{ name: "All", id: "All" }, ...res];
        setAllClientDetails(
          res?.length > 0
            ? setData?.map((d: any) => {
                return {
                  ...d,
                  label: d?.name,
                  value: d?.id,
                };
              })
            : []
        );
      })
      .catch((error) => {
        toast.error("Something went wrong!");
        setAllClientDetails([]);
      });
  };

  const fetchPaymentMethodData = () => {
    let data = {
      client_ids: selectClients?.toString(),
    };
    getClientPaymentMethodDetailsSettlement(data)
      .then((res) => {
        setClientPaymentMethod(res?.payment_methods || []);
        setClientCardType(res?.card_types || []);
      })
      .catch((error) => {
        toast.error("Something went wrong!");
        setClientPaymentMethod([]);
      });
  };

  const [isLoadingGenerate, setIsLoadingGenerate] = useState(false);
  const validateForm = () => {
    let isVAlidDate = false;

    const fioltersDatesErre = selectPaymentMethod
      ?.filter((f: any) => {
        return f?.isSelected;
      })
      .map((mData: any) => {
        return {
          ...mData,
          starting_date: mData?.starting_date ? "" : "Required field.",
          ending_date: mData?.ending_date ? "" : "Required field.",
          error_msg:
            mData?.starting_date && mData?.ending_date ? "" : "Required field.",
        };
      });

    setFormValuesErr(fioltersDatesErre);

    isVAlidDate = fioltersDatesErre?.every((sData: any) => {
      return sData?.error_msg == "";
    });
    return isVAlidDate;
  };

  useEffect(() => {
    validateForm();
  }, [selectPaymentMethod]);

  const handleSubmit = () => {
    if (!validateForm()) {
      return false;
    }
    let data: any = {
      organization_id: selectOrg,
      client_ids: selectClients.filter((f: any) => f != "All"),
      payment_methods: selectPaymentMethod
        ?.filter((f: any) => f.isSelected)
        ?.map((data: any) => {
          return {
            ...data,
            isSelected: undefined,
            starting_date: moment(data?.starting_date).format("YYYY-MM-DD"),
            ending_date: moment(data?.ending_date).format("YYYY-MM-DD"),
          };
        }),
      status: selectStatus.filter((f: any) => f != "All"),
      settlement_currency: selectCurrency,
      created_by: getUserId(),
    };
    onClose();

    if (info?.row?.original?.id) {
      updateSettlementRecordFun(data);
    } else {
      setSettlementAPISttus("in-progress");
      onClose();
      generateSettlementReport(data)
        .then((res) => {
          toast.success("Report created successfully!");
          fetchAllsettlement();
        })
        .catch((error) => {
          toast.error("Something went wrong");
        })
        .finally(() => {
          handleClose();
          setSettlementAPISttus("none");
        });
    }
  };

  useEffect(() => {
    if (selectOrg) {
      fetchClientData();
    }
  }, [selectOrg]);
  useEffect(() => {
    if (selectClients?.length > 0) {
      fetchPaymentMethodData();
    }
  }, [selectClients]);
  const loader = () => {
    if (isLoadingGenerate) {
      return <DivLoader className="m-5 h-6 w-6 border-indigo-500" />;
    }
  };
  return (
    <>
      <span>
        {info?.row?.original?.id ? (
          <MdEdit
            className="h-5 w-5 cursor-pointer text-blue-500"
            onClick={() => {
              onOpen();
              setSelectOrg(info?.row?.original?.organization?.id);
              setSelectClients(
                info?.row?.original?.client?.map((data: any) => {
                  return data?.id;
                })
              );
              setSelectStatus(info?.row?.original?.status);
              setSelectPaymentMethod(
                info?.row?.original?.payment_methods?.map((data: any) => {
                  return {
                    ...data,
                    isSelected: true,
                  };
                })
              );
            }}
          />
        ) : (
          <Card extra="w-fit px-1 cursor-pointer">
            <button
              onClick={() => {
                onOpen();
              }}
              className="flex items-center justify-center gap-2 whitespace-nowrap p-5 text-navy-700 outline-none dark:text-gray-200"
            >
              <BsPlusCircle className="h-5 w-5 text-brand-500" />
              <span className="text-brand-500">{"Generate Settlement"}</span>
            </button>
          </Card>
        )}
      </span>

      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="sm:max-w-xxl scrollbarhide z-[1002]  !m-auto flex max-h-[100vh]  max-w-[800px] justify-start   overflow-auto !p-3 sm:my-8 sm:w-full ">
          <ModalBody>
            <Card extra=" max-w-[800px] sm:w-full flex flex-col justify-start !z-[1004] max-h-[75vh]  sm:max-h-[100vh] scrollbarhide overflow-auto">
              <h1 className="p-5 px-[30px] text-2xl font-bold">
                {info?.row?.original?.id ? "Edit Report" : "Generate Report"}
              </h1>
              {loader()}

              <div className="max-h-[calc(100vh-200px)] overflow-auto overflow-x-hidden px-[30px] scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1 sm:max-h-[calc(100vh-300px)]">
                <div className="my-2">
                  <label className="">Organization</label>
                  <AntSelect
                    allowClear
                    showSearch
                    placeholder="Select organization"
                    optionFilterProp="label"
                    filterOption
                    className="mb-3 mt-2 w-full"
                    options={allOrgDetails}
                    value={selectOrg || undefined}
                    onChange={(event) => {
                      setSelectOrg(event);
                      setSelectClients([]);
                    }}
                    notFoundContent={"No options found"}
                  />
                </div>
                {true && (
                  <div className="relative my-2">
                    <label>Client(s)</label>
                    <AntSelect
                      onClear={() => {
                        setSelectClients([]);
                      }}
                      allowClear
                      showSearch
                      disabled={!selectOrg}
                      placeholder="Select client(s)"
                      optionFilterProp="label"
                      filterOption
                      options={allClientDetails}
                      mode="multiple"
                      onSelect={(event: any) => {
                        let isAll = event == "All";
                        if (isAll) {
                          setSelectClients(
                            allClientDetails.map((m: any) => m?.value)
                          );
                        }
                        if (!isAll) {
                          setSelectClients((prev: any) => {
                            return [...prev, event];
                          });
                        }
                      }}
                      onDeselect={(event: any) => {
                        let isAll = event == "All";
                        if (isAll) {
                          setSelectClients([]);
                        }
                        if (!isAll) {
                          setSelectClients((prev: any) => {
                            let fStatus = prev.filter((f: any) => {
                              return f != event;
                            });
                            return fStatus.filter((f: any) => f != "All");
                          });
                        }
                      }}
                      value={selectClients || undefined}
                      className="mb-3 mt-2 w-full"
                      notFoundContent={"No options found"}
                    />
                  </div>
                )}
                {selectClients?.length > 0 && (
                  <div className="my-2 px-1">
                    <label>Payment Methods</label>
                    <div className=" overflow-auto">
                      {clientCardType.map((method: any, index: any) => {
                        return (
                          <div className="m-2 grid  grid-cols-3 items-center  p-2">
                            <div className="col-span-1">
                              <Checkbox
                                id={method}
                                value={
                                  !selectPaymentMethod?.filter(
                                    (f: any) => f.name == method
                                  )[0]?.isSelected
                                }
                                className={`${
                                  true ? "h-4 w-4 dark:bg-gray-700" : "h-4 w-4"
                                }`}
                                color={false || null ? "gray" : ""}
                                checked={
                                  selectPaymentMethod?.filter(
                                    (f: any) => f.name == method
                                  )[0]?.isSelected
                                }
                                onChange={(e: any) => {
                                  setSelectPaymentMethod((prev: any) => {
                                    if (
                                      prev?.filter(
                                        (f: any) => f?.name == e.target.id
                                      )?.length > 0
                                    ) {
                                      return prev?.map((mData: any) => {
                                        if (mData.name == e.target.id) {
                                          return {
                                            ...mData,
                                            isSelected:
                                              e.target.value == "true"
                                                ? true
                                                : false,
                                          };
                                        } else {
                                          return mData;
                                        }
                                      });
                                    } else {
                                      let a = [];

                                      if (prev?.length > 0) {
                                        a = [...prev];
                                        a.push({
                                          name: e.target.id,
                                          isSelected:
                                            e.target.value == "true"
                                              ? true
                                              : false,
                                        });
                                      } else {
                                        a.push({
                                          name: e.target.id,
                                          isSelected:
                                            e.target.value == "true"
                                              ? true
                                              : false,
                                        });
                                      }

                                      return a;
                                    }
                                  });
                                }}
                              />{" "}
                              <label className="text-md mx-1">{method}</label>
                            </div>

                            <div className="col-span-2">
                              {false && (
                                <DateRange
                                  id={method}
                                  onDateChange={(
                                    dateRange: [Date | null, Date | null]
                                  ) => {
                                    let a = moment(
                                      new Date(dateRange[0])
                                    ).format("YYYY-MM-DD");
                                    let b = moment(
                                      new Date(dateRange[1])
                                    ).format("YYYY-MM-DD");
                                    if (true) {
                                      setSelectPaymentMethod((prev: any) => {
                                        if (
                                          prev?.filter(
                                            (f: any) => f?.name == method
                                          )?.length > 0
                                        ) {
                                          return prev?.map((mData: any) => {
                                            if (
                                              mData?.name?.toLowerCase() ==
                                              method?.toLowerCase()
                                            ) {
                                              return {
                                                ...mData,
                                                starting_date: a,
                                                ending_date: b,
                                              };
                                            } else {
                                              return mData;
                                            }
                                          });
                                        }
                                      });
                                    }
                                  }}
                                />
                              )}
                              {true && (
                                <>
                                  <DatePicker
                                    className="w-full rounded border p-3"
                                    placeholderText="Select Date Range"
                                    startDate={
                                      selectPaymentMethod?.filter(
                                        (f: any) => f.name == method
                                      )[0]?.starting_date &&
                                      new Date(
                                        selectPaymentMethod?.filter(
                                          (f: any) => f.name == method
                                        )[0]?.starting_date
                                      )
                                    }
                                    endDate={
                                      selectPaymentMethod?.filter(
                                        (f: any) => f.name == method
                                      )[0]?.ending_date &&
                                      new Date(
                                        selectPaymentMethod?.filter(
                                          (f: any) => f.name == method
                                        )[0]?.ending_date
                                      )
                                    }
                                    selectsRange={true}
                                    disabled={
                                      selectPaymentMethod?.filter(
                                        (f: any) => f.name == method
                                      )[0]?.isSelected == true
                                        ? false
                                        : true
                                    }
                                    onChange={(dateRange) => {
                                      const [a, b] = dateRange;
                                      if (true) {
                                        setSelectPaymentMethod((prev: any) => {
                                          if (
                                            prev?.filter(
                                              (f: any) => f?.name == method
                                            )?.length > 0
                                          ) {
                                            return prev?.map((mData: any) => {
                                              if (
                                                mData?.name?.toLowerCase() ==
                                                method?.toLowerCase()
                                              ) {
                                                return {
                                                  ...mData,
                                                  starting_date: a,
                                                  ending_date: b,
                                                };
                                              } else {
                                                return mData;
                                              }
                                            });
                                          }
                                        });
                                      }
                                    }}
                                  />
                                  <div>
                                    <span
                                      id="standard_error_help"
                                      className="mx-2 text-xs text-red-600 dark:text-red-400"
                                    >
                                      {
                                        formValuesErr?.filter((f: any) => {
                                          return (
                                            f?.name == method &&
                                            f?.error_msgs != ""
                                          );
                                        })[0]?.error_msg
                                      }
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })}

                      {false &&
                        clientPaymentMethod.map((method: any) => {
                          return (
                            <div className="m-2 grid grid-cols-3 items-center p-2">
                              <div className="col-span-1">
                                <Checkbox
                                  id={method}
                                  value={
                                    !selectPaymentMethod?.filter(
                                      (f: any) => f.name == method
                                    )[0]?.isSelected
                                  }
                                  className={`${
                                    true
                                      ? "h-4 w-4 dark:bg-gray-700"
                                      : "h-4 w-4"
                                  }`}
                                  color={false || null ? "gray" : ""}
                                  checked={
                                    selectPaymentMethod?.filter(
                                      (f: any) => f.name == method
                                    )[0]?.isSelected
                                  }
                                  onChange={(e: any) => {
                                    setSelectPaymentMethod((prev: any) => {
                                      if (
                                        prev?.filter(
                                          (f: any) => f?.name == e.target.id
                                        )?.length > 0
                                      ) {
                                        return prev?.map((mData: any) => {
                                          if (mData.name == e.target.id) {
                                            return {
                                              ...mData,
                                              isSelected:
                                                e.target.value == "true"
                                                  ? true
                                                  : false,
                                            };
                                          } else {
                                            return mData;
                                          }
                                        });
                                      } else {
                                        let a = [];

                                        if (prev?.length > 0) {
                                          a = prev;
                                          a.push({
                                            name: e.target.id,
                                            isSelected:
                                              e.target.value == "true"
                                                ? true
                                                : false,
                                          });
                                        } else {
                                          a.push({
                                            name: e.target.id,
                                            isSelected:
                                              e.target.value == "true"
                                                ? true
                                                : false,
                                          });
                                        }

                                        return a;
                                      }
                                    });
                                  }}
                                />{" "}
                                <label className="text-md mx-1">{method}</label>
                              </div>

                              <div className="col-span-2">
                                <DateRange
                                  id={method}
                                  cStartdate={
                                    selectPaymentMethod?.filter(
                                      (f: any) => f.name == method
                                    )[0]?.starting_date
                                  }
                                  cEnddate={
                                    selectPaymentMethod?.filter(
                                      (f: any) => f.name == method
                                    )[0]?.ending_date
                                  }
                                  onDateChange={(
                                    dateRange: [Date | null, Date | null]
                                  ) => {
                                    let a = moment(
                                      new Date(dateRange[0])
                                    ).format("YYYY-MM-DD");
                                    let b = moment(
                                      new Date(dateRange[1])
                                    ).format("YYYY-MM-DD");
                                    setSelectPaymentMethod((prev: any) => {
                                      if (
                                        prev?.filter(
                                          (f: any) => f?.name == method
                                        )?.length > 0
                                      ) {
                                        return prev?.map((mData: any) => {
                                          if (mData.name == method) {
                                            return {
                                              ...mData,
                                              starting_date: a || null,
                                              ending_date: b || null,
                                            };
                                          } else {
                                            return mData;
                                          }
                                        });
                                      }
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}

                <div className="my-2">
                  <label>Settlement Currency</label>
                  <AntSelect
                    showSearch
                    allowClear
                    placeholder="Select currency"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input?.toUpperCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    className="mb-3 mt-2 w-full"
                    options={currencyCodes.map((currencyCode) => {
                      const currencyDetails = getCurrencySymbol(currencyCode);
                      return {
                        value: currencyCode,
                        label: `${currencyCode}: ${currencyDetails.title?.toUpperCase()}`,
                      };
                    })}
                    value={selectCurrency || undefined}
                    onChange={(e) => {
                      setSelectCurrency(e);
                    }}
                    notFoundContent={"No options found"}
                  />
                </div>
              </div>
              <div className="mt-5 flex gap-2 px-[30px] pb-[30px]">
                <button
                  onClick={handleClose}
                  disabled={isLoadingGenerate}
                  className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Close
                </button>
                {true && (
                  <button
                    onClick={handleSubmit}
                    disabled={!checkFilterData() || isLoadingGenerate}
                    className="linear rounded-xl bg-indigo-50 px-5 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-indigo-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                  >
                    {info?.row?.original?.id ? "Update" : "Create"}
                  </button>
                )}
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CrerateSettlementReport;
