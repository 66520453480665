import { getClient } from "api/clients";
import moment from "moment";

const cell_alignment = {
  vertical: "top",
  horizontal: "right",
};
const createRowAndColumns = (array: any, worksheet: any) => {
  array.map((m: any) => {
    return (worksheet.getColumn(m.column).width = m.width);
  });
};

const setCellData = (
  array: any,
  worksheet: any,
  valueData: any,
  alignmentData: any,
  fontData: any,
  fillData: any,
  borderData: any
) => {
  worksheet.getCell(array).value = valueData || "";
  worksheet.getCell(array).alignment = alignmentData || "";
  worksheet.getCell(array).font = fontData || "";
  worksheet.getCell(array).fill = fillData || "";
  worksheet.getCell(array).border = borderData || "";
};

export default function ReportExcelSheets(
  jsonData: any,
  workbook: any,
  templateColor: any
) {
  try {
    // // console.log("Summary report ::", jsonData)
    jsonData?.excelData?.map((sheets: any, index: number) => {
      // summary report
      if (sheets?.sheet_type == "SUMMARY" && sheets?.sheetdata) {
        const worksheet = workbook.addWorksheet(`${sheets?.sheetname}`);
        createRowAndColumns(
          [
            { column: "B", width: "22.17" },
            { column: "C", width: "14.17" },
            { column: "D", width: "11.38" },
            { column: "E", width: "11.38" },
            { column: "F", width: "12.57" },
            { column: "G", width: "23.82" },
            { column: "H", width: "12" },
            { column: "I", width: "1" },
          ],
          worksheet
        );
        // organization Name
        worksheet.mergeCells("B3:I3");
        worksheet.getCell("B3").value = `${
          sheets?.sheetdata?.organization?.toUpperCase() ?? ""
        } SETTLEMENT REPORT`;
        worksheet.getCell("B3:I3").alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getCell("B3").font = {
          bold: true,
          color: { argb: templateColor?.yellow },
        };
        // Add an empty row after the last entry
        worksheet.addRow([]);
        // divider
        worksheet.mergeCells("B5:I5");
        worksheet.getCell("B5").fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: templateColor?.blue },
        };
        // Add an empty row after the last entry
        worksheet.addRow([]);
        // settlement period
        worksheet.mergeCells("G7:I7");
        worksheet.mergeCells("G8:I8");
        worksheet.getCell("G7").value = `Settlement Period: ${
          sheets?.sheetdata?.settlement_period ?? ""
        }`;
        worksheet.getCell("G7").alignment = {
          vertical: "top",
          horizontal: "center",
        };
        worksheet.getCell("G7").font = {
          bold: true,
          color: { argb: templateColor?.white },
        };
        worksheet.getCell("G7").fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: templateColor?.blue },
        };
        worksheet.getCell("G8").fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: templateColor?.blue },
        };

        sheets?.sheetdata?.clients?.map((client: any, index: number) => {
          // client name header
          setCellData(
            `B${7 + index}`,
            worksheet,
            "Client Name:",
            {
              vertical: "top",
              horizontal: "right",
            },
            { bold: true, color: { argb: templateColor?.white } },
            {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: templateColor?.blue },
            },
            ""
          );
          setCellData(
            `C${7 + index}`,
            worksheet,
            `${client}`,
            {
              vertical: "top",
              horizontal: "right",
            },
            { bold: true, color: { argb: templateColor?.black } },
            {},
            {
              top: { style: "thin", color: { argb: templateColor?.blue } },
              left: { style: "thin", color: { argb: templateColor?.blue } },
              bottom: { style: "thin", color: { argb: templateColor?.blue } },
              right: { style: "thin", color: { argb: templateColor?.blue } },
            }
          );
          // client name value
        });
        // Add an empty row after the last entry
        worksheet.addRow([]);

        let targetRowForTransactionCurrency = worksheet?.lastRow?.number + 1;

        // transaction currency
        setCellData(
          `B${targetRowForTransactionCurrency}`,
          worksheet,
          "Transaction Currency:",
          {
            vertical: "top",
            horizontal: "right",
          },
          {
            bold: true,
            color: { argb: templateColor?.white },
          },
          {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: templateColor?.blue },
          },
          ""
        );

        // transaction currency value
        setCellData(
          `C${targetRowForTransactionCurrency}`,
          worksheet,
          `${sheets?.sheetdata?.transaction_currencies?.toUpperCase() ?? ""}`,
          {
            vertical: "top",
            horizontal: "right",
          },
          {
            bold: true,
            color: { argb: templateColor?.black },
          },
          "",
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
            left: { style: "thin", color: { argb: templateColor?.blue } },
            bottom: { style: "thin", color: { argb: templateColor?.blue } },
            right: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );

        let targetRowForSrettlementCurrency = worksheet?.lastRow?.number + 1;
        let targetRowForDateRange = worksheet?.lastRow?.number;

        // settlement currency
        setCellData(
          `B${targetRowForSrettlementCurrency}`,
          worksheet,
          "Settlement Currency:",
          {
            vertical: "top",
            horizontal: "right",
          },
          {
            bold: true,
            color: { argb: templateColor?.white },
          },
          {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: templateColor?.blue },
          },
          ""
        );

        // settlement currency value
        setCellData(
          `C${targetRowForSrettlementCurrency}`,
          worksheet,
          `${sheets?.sheetdata?.settlement_currency?.toUpperCase() ?? ""}`,
          {
            vertical: "top",
            horizontal: "right",
          },
          {
            bold: true,
            color: { argb: templateColor?.black },
          },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
            left: { style: "thin", color: { argb: templateColor?.blue } },
            bottom: { style: "thin", color: { argb: templateColor?.blue } },
            right: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );

        // date range
        sheets?.sheetdata?.date_range?.map((dateRange: any, index: number) => {
          setCellData(
            `G${targetRowForDateRange + index}`,
            worksheet,
            `${dateRange?.name} Date range`,
            {
              vertical: "top",
              horizontal: "right",
            },
            {
              bold: true,
              color: { argb: templateColor?.white },
            },
            {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: templateColor?.blue },
            },
            ""
          );

          // value
          worksheet.mergeCells(
            `H${targetRowForDateRange + index}:I${
              targetRowForDateRange + index
            }`
          );
          setCellData(
            `H${targetRowForDateRange + index}:I${
              targetRowForDateRange + index
            }`,
            worksheet,
            `${moment(dateRange?.starting_date)?.format(
              "DD/MMM/YYYY HH:mm:ss [UTC]"
            )}`,
            { vertical: "top", horizontal: "right" },
            { color: { argb: templateColor?.black } },
            {},
            {
              top: { style: "thin", color: { argb: templateColor?.blue } },
              left: { style: "thin", color: { argb: templateColor?.blue } },
              bottom: { style: "thin", color: { argb: templateColor?.blue } },
              right: { style: "thin", color: { argb: templateColor?.blue } },
            }
          );
        });

        // Add an empty row after the last entry
        worksheet.addRow([]);

        let targetRowForDivider2 = worksheet?.lastRow?.number + 1;

        // divider
        worksheet.mergeCells(
          `B${targetRowForDivider2}:I${targetRowForDivider2}`
        );
        setCellData(
          `B${targetRowForDivider2}`,
          worksheet,
          "",
          {},
          {},
          {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: templateColor?.blue },
          },
          {}
        );

        // Add an empty row after the last entry
        worksheet.addRow([]);

        let targetRowForTransactionCount = worksheet?.lastRow?.number + 1;
        let targetRowForPSP = worksheet?.lastRow?.number + 1;

        // transaction count
        worksheet.mergeCells(
          `B${targetRowForTransactionCount}:D${targetRowForTransactionCount}`
        );

        setCellData(
          `B${targetRowForTransactionCount}`,
          worksheet,
          "Transaction Count",
          {
            vertical: "top",
            horizontal: "center",
          },
          {
            bold: true,
            color: { argb: templateColor?.white },
          },
          {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: templateColor?.blue },
          },
          {}
        );

        // PSP

        setCellData(
          `G${targetRowForPSP}`,
          worksheet,
          "PSP:",
          {
            vertical: "top",
            horizontal: "right",
          },
          {
            bold: true,
            color: { argb: templateColor?.white },
          },
          {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: templateColor?.blue },
          },
          {}
        );
        setCellData(
          `H${targetRowForPSP}`,
          worksheet,
          `${sheets?.sheetdata?.psp ?? ""}`,
          {
            vertical: "top",
            horizontal: "right",
          },
          {
            bold: true,
            color: { argb: templateColor?.black },
          },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
            left: { style: "thin", color: { argb: templateColor?.blue } },
            bottom: { style: "thin", color: { argb: templateColor?.blue } },
            right: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );

        // Add an empty row after the last entry
        worksheet.addRow([]);

        let targetRowForApprovedTransaction = worksheet?.lastRow?.number + 1;
        let targetRowForPayoutDate = worksheet?.lastRow?.number + 1;

        // payout date
        setCellData(
          `G${targetRowForPayoutDate}`,
          worksheet,
          "Payout Date:",
          {
            vertical: "top",
            horizontal: "right",
          },
          {
            bold: true,
            color: { argb: templateColor?.white },
          },
          {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: templateColor?.blue },
          },
          {}
        );
        setCellData(
          `H${targetRowForPayoutDate}`,
          worksheet,
          `${moment(sheets?.sheetdata?.date)?.format("DD-MMM-YY")}`,
          {
            vertical: "top",
            horizontal: "right",
          },
          {
            bold: true,
            color: { argb: templateColor?.black },
          },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
            left: { style: "thin", color: { argb: templateColor?.blue } },
            bottom: { style: "thin", color: { argb: templateColor?.blue } },
            right: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );

        // approved transactions
        setCellData(
          `B${targetRowForApprovedTransaction}`,
          worksheet,
          "Approved Transactions:",
          {
            vertical: "top",
            horizontal: "right",
          },
          {
            color: { argb: templateColor?.black },
          },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );

        // approved transactions value
        setCellData(
          `C${targetRowForApprovedTransaction}`,
          worksheet,
          `${
            sheets?.sheetdata?.transaction_count?.approved_transactions
              ?.count ?? ""
          }`,
          {
            vertical: "top",
            horizontal: "right",
          },
          {
            color: { argb: templateColor?.yellow },
          },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );
        // approved transactions value percentage

        setCellData(
          `D${targetRowForApprovedTransaction}`,
          worksheet,
          `${
            sheets?.sheetdata?.transaction_count?.approved_transactions?.cost ??
            ""
          }`,
          {
            vertical: "top",
            horizontal: "right",
          },
          {
            color: { argb: templateColor?.blue },
          },

          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );
        let targetRowForDeclinedTransaction = worksheet?.lastRow?.number + 1;

        // declined transactions
        setCellData(
          `B${targetRowForDeclinedTransaction}`,
          worksheet,
          "Declined Transactions:",
          {
            vertical: "top",
            horizontal: "right",
          },
          {
            color: { argb: templateColor?.black },
          },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );

        // declined transactions value
        setCellData(
          `C${targetRowForDeclinedTransaction}`,
          worksheet,
          `${
            sheets?.sheetdata?.transaction_count?.declined_transactions
              ?.count ?? ""
          }`,
          {
            vertical: "top",
            horizontal: "right",
          },
          {
            color: { argb: templateColor?.yellow },
          },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );
        // declined transactions value percentage

        setCellData(
          `D${targetRowForDeclinedTransaction}`,
          worksheet,
          `${
            sheets?.sheetdata?.transaction_count?.declined_transactions?.cost ??
            ""
          }`,
          {
            vertical: "top",
            horizontal: "right",
          },
          {
            color: { argb: templateColor?.blue },
          },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );
        let targetRowForTransactionCalculationResult =
          worksheet?.lastRow?.number + 1;

        // transaction calculation result
        setCellData(
          `B${targetRowForTransactionCalculationResult}`,
          worksheet,
          "Total Transactions:",
          { vertical: "top", horizontal: "right" },
          { bold: true, color: { argb: templateColor?.white } },
          {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: templateColor?.blue },
          },
          {}
        );
        setCellData(
          `C${targetRowForTransactionCalculationResult}`,
          worksheet,
          `${sheets?.sheetdata?.transaction_count?.total_transactions ?? ""}`,
          { vertical: "top", horizontal: "right" },
          { bold: true, color: { argb: templateColor?.white } },
          {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: templateColor?.blue },
          },
          {}
        );

        setCellData(
          `D${targetRowForTransactionCalculationResult}`,
          worksheet,
          ``,
          {},
          {},
          {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: templateColor?.blue },
          },
          {}
        );
        // Add an empty row after the last entry
        worksheet.addRow([]);

        let targetRowForDivider3 = worksheet?.lastRow?.number + 1;

        // divider
        worksheet.mergeCells(
          `B${targetRowForDivider3}:I${targetRowForDivider3}`
        );
        worksheet.getCell(`B${targetRowForDivider3}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: templateColor?.blue },
        };

        // Add an empty row after the last entry
        worksheet.addRow([]);

        let targetRowForheadersCostCountAmount = worksheet?.lastRow?.number + 1;
        let targetRowForGrossVolume = worksheet?.lastRow?.number + 4;

        //headers for cost, amount, count & empty
        setCellData(
          `F${targetRowForheadersCostCountAmount}`,
          worksheet,
          "Cost",
          {},
          {
            bold: true,
            italic: true,
            color: { argb: templateColor?.black },
          },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
            bottom: { style: "thin", color: { argb: templateColor?.blue } },
            left: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );
        setCellData(
          `G${targetRowForheadersCostCountAmount}`,
          worksheet,
          "Count",
          {},
          {
            bold: true,
            color: { argb: templateColor?.black },
          },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
            bottom: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );
        setCellData(
          `H${targetRowForheadersCostCountAmount}`,
          worksheet,
          "Amount",
          {},
          {
            bold: true,
            color: { argb: templateColor?.black },
          },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
            bottom: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );
        worksheet.getCell(`I${targetRowForheadersCostCountAmount}`).border = {
          top: { style: "thin", color: { argb: templateColor?.blue } },
          bottom: { style: "thin", color: { argb: templateColor?.blue } },
          right: { style: "thin", color: { argb: templateColor?.blue } },
        };

        worksheet.getCell(`I${targetRowForheadersCostCountAmount}`);

        // gross volume
        worksheet.mergeCells(
          `B${targetRowForGrossVolume}:D${targetRowForGrossVolume}`
        );
        setCellData(
          `B${targetRowForGrossVolume}:D${targetRowForGrossVolume}`,
          worksheet,
          "Gross Volume",
          { vertical: "top", horizontal: "center" },
          { bold: true, color: { argb: templateColor?.white } },
          {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: templateColor?.blue },
          },
          {}
        );

        let targetRowForGrossVolumeProcessedAmountUpperBorder =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForGrossVolumeProcessedAmountUpperBorder}:I${targetRowForGrossVolumeProcessedAmountUpperBorder}`
        );
        worksheet.getCell(
          `B${targetRowForGrossVolumeProcessedAmountUpperBorder}:I${targetRowForGrossVolumeProcessedAmountUpperBorder}`
        ).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // processed
        let targetRowForGrossVolumeProcessedAmountValue =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForGrossVolumeProcessedAmountValue}:D${targetRowForGrossVolumeProcessedAmountValue}`
        );

        setCellData(
          `B${targetRowForGrossVolumeProcessedAmountValue}:D${targetRowForGrossVolumeProcessedAmountValue}`,
          worksheet,
          "Processed Amount:",
          { vertical: "top", horizontal: "right" },
          { bold: true, color: { argb: templateColor?.black } },
          {},
          {}
        );
        // cost
        setCellData(
          `F${targetRowForGrossVolumeProcessedAmountValue}`,
          worksheet,
          `${sheets?.sheetdata?.gross_volume?.processe_amount?.cost ?? ""}`,
          { vertical: "top", horizontal: "right" },
          { color: { argb: templateColor?.black } },
          {},
          {
            top: { style: "thick", color: { argb: templateColor?.blue } },
            bottom: { style: "thick", color: { argb: templateColor?.blue } },
          }
        );
        // count
        setCellData(
          `G${targetRowForGrossVolumeProcessedAmountValue}`,
          worksheet,
          `${sheets?.sheetdata?.gross_volume?.processe_amount?.count ?? ""}`,
          { vertical: "top", horizontal: "right" },
          { bold: true, color: { argb: templateColor?.black } },
          {},
          {
            top: { style: "thick", color: { argb: templateColor?.blue } },
            bottom: { style: "thick", color: { argb: templateColor?.blue } },
          }
        );

        // amount
        setCellData(
          `H${targetRowForGrossVolumeProcessedAmountValue}`,
          worksheet,
          `${
            sheets?.sheetdata?.gross_volume?.processe_amount?.amount
              ? sheets.currency_symbol
              : ""
          }${
            (Number(
              sheets?.sheetdata?.gross_volume?.processe_amount?.amount
            )?.toFixed(2) ||
              "") ??
            ""
          }`,
          { vertical: "top", horizontal: "right" },
          { bold: true, color: { argb: templateColor?.yellow } },
          {},
          {
            top: { style: "thick", color: { argb: templateColor?.blue } },
            bottom: { style: "thick", color: { argb: templateColor?.blue } },
          }
        );
        // empty
        setCellData(
          `I${targetRowForGrossVolumeProcessedAmountValue}`,
          worksheet,
          "",
          { vertical: "top", horizontal: "right" },
          { bold: true, color: { argb: templateColor?.black } },
          {},
          {
            top: { style: "thick", color: { argb: templateColor?.blue } },
            bottom: { style: "thick", color: { argb: templateColor?.blue } },
          }
        );
        // after processed
        let targetRowForGrossVolumeProcessedAmountBottomBorder =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForGrossVolumeProcessedAmountBottomBorder}:I${targetRowForGrossVolumeProcessedAmountBottomBorder}`
        );
        worksheet.getCell(
          `B${targetRowForGrossVolumeProcessedAmountBottomBorder}:I${targetRowForGrossVolumeProcessedAmountBottomBorder}`
        ).border = {
          top: { style: "thick", color: { argb: templateColor?.blue } },
        };

        let targetRowForDeductions = worksheet?.lastRow?.number + 1;

        // deductions
        worksheet.mergeCells(
          `B${targetRowForDeductions}:D${targetRowForDeductions}`
        );
        setCellData(
          `B${targetRowForDeductions}:D${targetRowForDeductions}`,
          worksheet,
          "Deductions",
          { vertical: "top", horizontal: "center" },
          { bold: true, color: { argb: templateColor?.white } },
          {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: templateColor?.blue },
          },
          {}
        );

        // add row for border after deductions
        let targetRowForDeductionsUpperBorder = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForDeductionsUpperBorder}:I${targetRowForDeductionsUpperBorder}`
        );
        worksheet.getCell(
          `B${targetRowForDeductionsUpperBorder}:I${targetRowForDeductionsUpperBorder}`
        ).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // without rate -> chargeback
        let targetRowForDeductionsDataChargeBack =
          worksheet?.lastRow?.number + 1;
        worksheet.mergeCells(
          `B${targetRowForDeductionsDataChargeBack}:D${targetRowForDeductionsDataChargeBack}`
        );
        worksheet.getCell(`B${targetRowForDeductionsDataChargeBack}`).value =
          "Chargebacks:";
        worksheet.getCell(`B${targetRowForDeductionsDataChargeBack}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `B${targetRowForDeductionsDataChargeBack}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // cost, count, amount for chargeback without rate
        // cost
        setCellData(
          `F${targetRowForDeductionsDataChargeBack}`,
          worksheet,
          `${
            sheets?.sheetdata?.deductions["not-rate"]?.chargeback_transactions
              ?.cost ?? ""
          }`,
          { vertical: "top", horizontal: "right" },
          {
            color: { argb: templateColor?.black },
          },
          {},
          {
            top: { style: "thick", color: { argb: templateColor?.blue } },
          }
        );
        // count
        setCellData(
          `G${targetRowForDeductionsDataChargeBack}`,
          worksheet,
          `${
            sheets?.sheetdata?.deductions["not-rate"]?.chargeback_transactions
              ?.count ?? ""
          }`,
          { vertical: "top", horizontal: "right" },
          {
            color: { argb: templateColor?.black },
          },
          {},
          {
            top: { style: "thick", color: { argb: templateColor?.blue } },
          }
        );

        // amount

        setCellData(
          `H${targetRowForDeductionsDataChargeBack}`,
          worksheet,
          `${
            sheets?.sheetdata?.deductions["not-rate"]?.chargeback_transactions
              ?.amount
              ? sheets.currency_symbol
              : ""
          }${
            (Number(
              sheets?.sheetdata?.deductions[
                "not-rate"
              ]?.chargeback_transactions?.amount?.toFixed(2)
            ) ||
              "") ??
            ""
          }`,
          { vertical: "top", horizontal: "right" },
          {
            color: { argb: templateColor?.black },
          },
          {},
          {
            top: { style: "thick", color: { argb: templateColor?.blue } },
          }
        );
        // empty
        setCellData(
          `I${targetRowForDeductionsDataChargeBack}`,
          worksheet,
          "",
          { vertical: "top", horizontal: "right" },
          {
            bold: true,
            color: { argb: templateColor?.black },
          },
          {},
          {
            top: { style: "thick", color: { argb: templateColor?.blue } },
          }
        );

        // without rate -> refund
        let targetRowForDeductionsDataRefund = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForDeductionsDataRefund}:D${targetRowForDeductionsDataRefund}`
        );
        setCellData(
          `B${targetRowForDeductionsDataRefund}`,
          worksheet,
          "Refunds:",
          {
            vertical: "top",
            horizontal: "right",
          },
          {
            color: { argb: templateColor?.black },
          },
          {},
          {}
        );
        // cost, count, amount for refund without rate
        // cost
        setCellData(
          `F${targetRowForDeductionsDataRefund}`,
          worksheet,
          `${
            sheets?.sheetdata?.deductions["not-rate"]?.refund_transactions
              ?.cost ?? ""
          }`,
          cell_alignment,
          {
            color: { argb: templateColor?.black },
          },
          {},
          {}
        );
        // count

        setCellData(
          `G${targetRowForDeductionsDataRefund}`,
          worksheet,
          `${
            sheets?.sheetdata?.deductions["not-rate"]?.refund_transactions
              ?.cost ?? ""
          }`,
          cell_alignment,
          {
            color: { argb: templateColor?.black },
          },
          {},
          {}
        );
        // amount

        setCellData(
          `H${targetRowForDeductionsDataRefund}`,
          worksheet,
          `${
            sheets?.sheetdata?.deductions["not-rate"]?.refund_transactions
              ?.amount
              ? sheets.currency_symbol
              : ""
          }${
            (Number(
              sheets?.sheetdata?.deductions["not-rate"]?.refund_transactions
                ?.amount
            )?.toFixed(2) ||
              "") ??
            ""
          }`,
          cell_alignment,
          {
            color: { argb: templateColor?.black },
          },
          {},
          {}
        );

        // empty
        setCellData(
          `I${targetRowForDeductionsDataRefund}`,
          worksheet,
          "",
          cell_alignment,
          {
            color: { argb: templateColor?.black },
          },
          {},
          {}
        );

        // without rate -> total deductions
        let targetRowForDeductionsDataTotalDeductions =
          worksheet?.lastRow?.number + 1;
        worksheet.mergeCells(
          `B${targetRowForDeductionsDataTotalDeductions}:D${targetRowForDeductionsDataTotalDeductions}`
        );

        setCellData(
          `B${targetRowForDeductionsDataTotalDeductions}`,
          worksheet,
          "Total Deductions:",
          cell_alignment,
          { bold: true, color: { argb: templateColor?.black } },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );
        worksheet.getCell(
          `E${targetRowForDeductionsDataTotalDeductions}`
        ).border = {
          top: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // cost, count, amount for total deductions without rate
        // cost

        setCellData(
          `F${targetRowForDeductionsDataTotalDeductions}`,
          worksheet,
          `${
            sheets?.sheetdata?.deductions["not-rate"]?.total_deductions?.cost ??
            ""
          }`,
          cell_alignment,
          { bold: true, color: { argb: templateColor?.black } },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );
        // count

        setCellData(
          `G${targetRowForDeductionsDataTotalDeductions}`,
          worksheet,
          `${
            sheets?.sheetdata?.deductions["not-rate"]?.total_deductions
              ?.count ?? ""
          }`,
          cell_alignment,
          { bold: true, color: { argb: templateColor?.black } },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );
        // amount

        setCellData(
          `G${targetRowForDeductionsDataTotalDeductions}`,
          worksheet,
          `${
            sheets?.sheetdata?.deductions["not-rate"]?.total_deductions
              ?.count ?? ""
          }`,
          cell_alignment,
          { bold: true, color: { argb: templateColor?.black } },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );
        // empty
        setCellData(
          `I${targetRowForDeductionsDataTotalDeductions}`,
          worksheet,
          "",
          cell_alignment,
          { bold: true, color: { argb: templateColor?.black } },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );
        // after deductions without rate
        let targetRowForDeductionsBottomBorder = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForDeductionsBottomBorder}:I${targetRowForDeductionsBottomBorder}`
        );
        worksheet.getCell(
          `B${targetRowForDeductionsBottomBorder}:I${targetRowForDeductionsBottomBorder}`
        ).border = {
          top: { style: "thick", color: { argb: templateColor?.blue } },
          bottom: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // with rate -> chargeback
        let targetRowForDeductionsDataChargeBackRate =
          worksheet?.lastRow?.number + 1;
        worksheet.mergeCells(
          `B${targetRowForDeductionsDataChargeBackRate}:D${targetRowForDeductionsDataChargeBackRate}`
        );

        setCellData(
          `B${targetRowForDeductionsDataChargeBackRate}`,
          worksheet,
          "Chargeback Rate:",
          cell_alignment,
          { italic: true, color: { argb: templateColor?.blue } },
          {},
          {}
        );

        // cost, count, amount for chargeback with rate
        // cost

        setCellData(
          `F${targetRowForDeductionsDataChargeBackRate}`,
          worksheet,
          `${sheets?.sheetdata?.deductions?.rate?.chargeback_rate?.cost ?? ""}`,
          cell_alignment,
          { bold: true, color: { argb: templateColor?.black } },
          {},
          {}
        );
        // count

        setCellData(
          `G${targetRowForDeductionsDataChargeBackRate}`,
          worksheet,
          `${
            (parseFloat(
              sheets?.sheetdata?.deductions?.rate?.chargeback_rate?.count
            )?.toFixed(2) + "%" ||
              "") ??
            ""
          }`,
          cell_alignment,
          { italic: true, color: { argb: templateColor?.blue } },
          {},
          {
            top: { style: "thin", color: { argb: templateColor?.blue } },
          }
        );

        // amount
        setCellData(
          `H${targetRowForDeductionsDataChargeBackRate}`,
          worksheet,
          `${
            (parseFloat(
              sheets?.sheetdata?.deductions?.rate?.chargeback_rate?.amount
            ).toFixed(2) + "%" ||
              "") ??
            ""
          }`,
          cell_alignment,
          { italic: true, color: { argb: templateColor?.blue } },
          {},
          {}
        );

        // empty

        setCellData(
          `I${targetRowForDeductionsDataChargeBackRate}`,
          worksheet,
          "",
          cell_alignment,
          { bold: true, color: { argb: templateColor?.black } },
          {},
          {}
        );

        // with rate -> refund
        let targetRowForDeductionsDataRefundRate =
          worksheet?.lastRow?.number + 1;
        worksheet.mergeCells(
          `B${targetRowForDeductionsDataRefundRate}:D${targetRowForDeductionsDataRefundRate}`
        );
        setCellData(
          `I${targetRowForDeductionsDataChargeBackRate}`,
          worksheet,
          "Refund Rate:",
          cell_alignment,
          { italic: true, color: { argb: templateColor?.blue } },
          {},
          {}
        );
        // cost, count, amount for refund with rate
        // cost
        setCellData(
          `F${targetRowForDeductionsDataRefundRate}`,
          worksheet,
          `${sheets?.sheetdata?.deductions?.rate?.refund_rate?.cost ?? ""}`,
          cell_alignment,
          { bold: true, color: { argb: templateColor?.black } },
          {},
          {}
        );
        // count

        setCellData(
          `G${targetRowForDeductionsDataRefundRate}`,
          worksheet,
          `${
            (parseFloat(
              sheets?.sheetdata?.deductions?.rate?.refund_rate?.count
            )?.toFixed(2) + "%" ||
              "") ??
            ""
          }`,
          cell_alignment,
          { italic: true, color: { argb: templateColor?.blue } },
          {},
          {}
        );
        // amount

        setCellData(
          `H${targetRowForDeductionsDataRefundRate}`,
          worksheet,
          `${
            (parseFloat(
              sheets?.sheetdata?.deductions?.rate?.refund_rate?.amount
            ).toFixed(2) + "%" ||
              "") ??
            ""
          }`,
          cell_alignment,
          { italic: true, color: { argb: templateColor?.blue } },
          {},
          {}
        );
        // empty
        worksheet.getCell(`I${targetRowForDeductionsDataRefundRate}`).value =
          "";
        worksheet.getCell(`I${targetRowForDeductionsDataRefundRate}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `I${targetRowForDeductionsDataRefundRate}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // with rate -> total deductions
        let targetRowForDeductionsDataTotalDeductionsRate =
          worksheet?.lastRow?.number + 1;
        worksheet.mergeCells(
          `B${targetRowForDeductionsDataTotalDeductionsRate}:D${targetRowForDeductionsDataTotalDeductionsRate}`
        );
        worksheet.getCell(
          `B${targetRowForDeductionsDataTotalDeductionsRate}`
        ).value = "Total Deductions:";
        worksheet.getCell(
          `B${targetRowForDeductionsDataTotalDeductionsRate}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForDeductionsDataTotalDeductionsRate}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `B${targetRowForDeductionsDataTotalDeductionsRate}`
        ).border = {
          top: { style: "thin", color: { argb: templateColor?.blue } },
        };
        worksheet.getCell(
          `E${targetRowForDeductionsDataTotalDeductionsRate}`
        ).border = {
          top: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // cost, count, amount for total deductions with rate
        // cost
        worksheet.getCell(
          `F${targetRowForDeductionsDataTotalDeductionsRate}`
        ).value = `${
          sheets?.sheetdata?.deductions?.rate?.total_deductions_rate?.cost ?? ""
        }`;
        worksheet.getCell(
          `F${targetRowForDeductionsDataTotalDeductionsRate}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `F${targetRowForDeductionsDataTotalDeductionsRate}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `F${targetRowForDeductionsDataTotalDeductionsRate}`
        ).border = {
          top: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // count
        worksheet.getCell(
          `G${targetRowForDeductionsDataTotalDeductionsRate}`
        ).value = `${
          sheets?.sheetdata?.deductions?.rate?.total_deductions_rate?.count ??
          ""
        }`;
        worksheet.getCell(
          `G${targetRowForDeductionsDataTotalDeductionsRate}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `G${targetRowForDeductionsDataTotalDeductionsRate}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `G${targetRowForDeductionsDataTotalDeductionsRate}`
        ).border = {
          top: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // amount
        worksheet.getCell(
          `H${targetRowForDeductionsDataTotalDeductionsRate}`
        ).value = `${
          (parseFloat(
            sheets?.sheetdata?.deductions?.rate?.total_deductions_rate?.amount
          )?.toFixed(2) + "%" ||
            "") ??
          ""
        }`;
        worksheet.getCell(
          `H${targetRowForDeductionsDataTotalDeductionsRate}`
        ).font = { bold: true, color: { argb: templateColor?.yellow } };
        worksheet.getCell(
          `H${targetRowForDeductionsDataTotalDeductionsRate}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `H${targetRowForDeductionsDataTotalDeductionsRate}`
        ).border = {
          top: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // empty
        worksheet.getCell(
          `I${targetRowForDeductionsDataTotalDeductionsRate}`
        ).value = "";
        worksheet.getCell(
          `I${targetRowForDeductionsDataTotalDeductionsRate}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `I${targetRowForDeductionsDataTotalDeductionsRate}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `I${targetRowForDeductionsDataTotalDeductionsRate}`
        ).border = {
          top: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // after deductions with rate
        let targetRowForDeductionsBottomBorderRate =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForDeductionsBottomBorderRate}:I${targetRowForDeductionsBottomBorderRate}`
        );
        worksheet.getCell(
          `B${targetRowForDeductionsBottomBorderRate}:I${targetRowForDeductionsBottomBorderRate}`
        ).border = {
          top: { style: "thick", color: { argb: templateColor?.blue } },
        };

        let targetRowForCharges = worksheet?.lastRow?.number + 1;

        // Charges
        worksheet.mergeCells(`B${targetRowForCharges}:D${targetRowForCharges}`);
        worksheet.getCell(
          `B${targetRowForCharges}:D${targetRowForCharges}`
        ).value = "Charges";
        worksheet.getCell(
          `B${targetRowForCharges}:D${targetRowForCharges}`
        ).font = { bold: true, color: { argb: templateColor?.white } };
        worksheet.getCell(
          `B${targetRowForCharges}:D${targetRowForCharges}`
        ).alignment = { vertical: "top", horizontal: "center" };
        worksheet.getCell(
          `B${targetRowForCharges}:D${targetRowForCharges}`
        ).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: templateColor?.blue },
        };

        let targetRowForChargesUpperBorder = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForChargesUpperBorder}:I${targetRowForChargesUpperBorder}`
        );
        worksheet.getCell(
          `B${targetRowForChargesUpperBorder}:I${targetRowForChargesUpperBorder}`
        ).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // Transaction Fee %
        let targetRowForChargesTransactionFeeValue =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForChargesTransactionFeeValue}:D${targetRowForChargesTransactionFeeValue}`
        );
        worksheet.getCell(
          `B${targetRowForChargesTransactionFeeValue}:D${targetRowForChargesTransactionFeeValue}`
        ).value = "Transaction Fee %:";
        worksheet.getCell(
          `B${targetRowForChargesTransactionFeeValue}:D${targetRowForChargesTransactionFeeValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForChargesTransactionFeeValue}:D${targetRowForChargesTransactionFeeValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // cost
        worksheet.getCell(
          `F${targetRowForChargesTransactionFeeValue}`
        ).value = `${
          sheets?.sheetdata?.charge_fees?.transaction_fees?.cost
            ?.upper_limit_percentage_fees ?? ""
        } - ${
          sheets?.sheetdata?.charge_fees?.transaction_fees?.cost
            ?.lower_limit_percentage_fees ?? ""
        }`;
        worksheet.getCell(`F${targetRowForChargesTransactionFeeValue}`).font = {
          italic: true,
          color: { argb: templateColor?.blue },
        };
        worksheet.getCell(
          `F${targetRowForChargesTransactionFeeValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`F${targetRowForChargesTransactionFeeValue}`).border =
          {
            top: { style: "thick", color: { argb: templateColor?.blue } },
          };

        // count
        worksheet.getCell(
          `G${targetRowForChargesTransactionFeeValue}`
        ).value = `${
          sheets?.sheetdata?.charge_fees?.transaction_fees?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForChargesTransactionFeeValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `G${targetRowForChargesTransactionFeeValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`G${targetRowForChargesTransactionFeeValue}`).border =
          {
            top: { style: "thick", color: { argb: templateColor?.blue } },
          };

        // amount
        worksheet.getCell(
          `H${targetRowForChargesTransactionFeeValue}`
        ).value = `${
          sheets?.sheetdata?.charge_fees?.transaction_fees?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.charge_fees?.transaction_fees?.amount
          )?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(`H${targetRowForChargesTransactionFeeValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `H${targetRowForChargesTransactionFeeValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`H${targetRowForChargesTransactionFeeValue}`).border =
          {
            top: { style: "thick", color: { argb: templateColor?.blue } },
          };

        // empty
        worksheet.getCell(`I${targetRowForChargesTransactionFeeValue}`).value =
          "";
        worksheet.getCell(`I${targetRowForChargesTransactionFeeValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `I${targetRowForChargesTransactionFeeValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`I${targetRowForChargesTransactionFeeValue}`).border =
          {
            top: { style: "thick", color: { argb: templateColor?.blue } },
          };

        // Fixed Transaction Fee
        let targetRowForChargesFixedTransactionFeeValue =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForChargesFixedTransactionFeeValue}:D${targetRowForChargesFixedTransactionFeeValue}`
        );
        worksheet.getCell(
          `B${targetRowForChargesFixedTransactionFeeValue}:D${targetRowForChargesFixedTransactionFeeValue}`
        ).value = "Fixed Transaction Fee:";
        worksheet.getCell(
          `B${targetRowForChargesFixedTransactionFeeValue}:D${targetRowForChargesFixedTransactionFeeValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForChargesFixedTransactionFeeValue}:D${targetRowForChargesFixedTransactionFeeValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // cost
        worksheet.getCell(
          `F${targetRowForChargesFixedTransactionFeeValue}`
        ).value = `${
          sheets?.sheetdata?.charge_fees?.fixed_transaction_fees?.cost
            ?.upper_limit_fixed_fees ?? ""
        } - ${
          sheets?.sheetdata?.charge_fees?.fixed_transaction_fees?.cost
            ?.lower_limit_fixed_fees ?? ""
        }`;
        worksheet.getCell(
          `F${targetRowForChargesFixedTransactionFeeValue}`
        ).font = { italic: true, color: { argb: templateColor?.blue } };
        worksheet.getCell(
          `F${targetRowForChargesFixedTransactionFeeValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // count
        worksheet.getCell(
          `G${targetRowForChargesFixedTransactionFeeValue}`
        ).value = `${
          sheets?.sheetdata?.charge_fees?.fixed_transaction_fees?.count ?? ""
        }`;
        worksheet.getCell(
          `G${targetRowForChargesFixedTransactionFeeValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `G${targetRowForChargesFixedTransactionFeeValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // amount
        worksheet.getCell(
          `H${targetRowForChargesFixedTransactionFeeValue}`
        ).value = `${
          sheets?.sheetdata?.charge_fees?.fixed_transaction_fees?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.charge_fees?.fixed_transaction_fees?.amount
          ).toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(
          `H${targetRowForChargesFixedTransactionFeeValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `H${targetRowForChargesFixedTransactionFeeValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // empty
        worksheet.getCell(
          `I${targetRowForChargesFixedTransactionFeeValue}`
        ).value = "";
        worksheet.getCell(
          `I${targetRowForChargesFixedTransactionFeeValue}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `I${targetRowForChargesFixedTransactionFeeValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // DeclinedTransaction
        let targetRowForChargesDeclinedTransactionValue =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForChargesDeclinedTransactionValue}:D${targetRowForChargesDeclinedTransactionValue}`
        );
        worksheet.getCell(
          `B${targetRowForChargesDeclinedTransactionValue}:D${targetRowForChargesDeclinedTransactionValue}`
        ).value = "Declined Transaction:";
        worksheet.getCell(
          `B${targetRowForChargesDeclinedTransactionValue}:D${targetRowForChargesDeclinedTransactionValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForChargesDeclinedTransactionValue}:D${targetRowForChargesDeclinedTransactionValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // cost
        worksheet.getCell(
          `F${targetRowForChargesDeclinedTransactionValue}`
        ).value = `${
          sheets?.sheetdata?.charge_fees?.declined_fees?.cost ?? ""
        }`;
        worksheet.getCell(
          `F${targetRowForChargesDeclinedTransactionValue}`
        ).font = { italic: true, color: { argb: templateColor?.blue } };
        worksheet.getCell(
          `F${targetRowForChargesDeclinedTransactionValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // count
        worksheet.getCell(
          `G${targetRowForChargesDeclinedTransactionValue}`
        ).value = `${
          sheets?.sheetdata?.charge_fees?.declined_fees?.count ?? ""
        }`;
        worksheet.getCell(
          `G${targetRowForChargesDeclinedTransactionValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `G${targetRowForChargesDeclinedTransactionValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // amount
        worksheet.getCell(
          `H${targetRowForChargesDeclinedTransactionValue}`
        ).value = `${
          sheets?.sheetdata?.charge_fees?.declined_fees?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.charge_fees?.declined_fees?.amount
          )?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(
          `H${targetRowForChargesDeclinedTransactionValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `H${targetRowForChargesDeclinedTransactionValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // empty
        worksheet.getCell(
          `I${targetRowForChargesDeclinedTransactionValue}`
        ).value = "";
        worksheet.getCell(
          `I${targetRowForChargesDeclinedTransactionValue}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `I${targetRowForChargesDeclinedTransactionValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // Chargebacks
        let targetRowForChargesChargebacksValue =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForChargesChargebacksValue}:D${targetRowForChargesChargebacksValue}`
        );
        worksheet.getCell(
          `B${targetRowForChargesChargebacksValue}:D${targetRowForChargesChargebacksValue}`
        ).value = "Chargebacks:";
        worksheet.getCell(
          `B${targetRowForChargesChargebacksValue}:D${targetRowForChargesChargebacksValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForChargesChargebacksValue}:D${targetRowForChargesChargebacksValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // cost
        worksheet.getCell(`F${targetRowForChargesChargebacksValue}`).value = `${
          sheets?.sheetdata?.charge_fees?.chargeback_fees?.cost ?? ""
        }`;
        worksheet.getCell(`F${targetRowForChargesChargebacksValue}`).font = {
          italic: true,
          color: { argb: templateColor?.blue },
        };
        worksheet.getCell(`F${targetRowForChargesChargebacksValue}`).alignment =
          { vertical: "top", horizontal: "right" };

        // count
        worksheet.getCell(`G${targetRowForChargesChargebacksValue}`).value = `${
          sheets?.sheetdata?.charge_fees?.chargeback_fees?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForChargesChargebacksValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`G${targetRowForChargesChargebacksValue}`).alignment =
          { vertical: "top", horizontal: "right" };

        // amount
        worksheet.getCell(`H${targetRowForChargesChargebacksValue}`).value = `${
          sheets?.sheetdata?.charge_fees?.chargeback_fees?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.charge_fees?.chargeback_fees?.amount
          )?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(`H${targetRowForChargesChargebacksValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`H${targetRowForChargesChargebacksValue}`).alignment =
          { vertical: "top", horizontal: "right" };

        // empty
        worksheet.getCell(`I${targetRowForChargesChargebacksValue}`).value = "";
        worksheet.getCell(`I${targetRowForChargesChargebacksValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`I${targetRowForChargesChargebacksValue}`).alignment =
          { vertical: "top", horizontal: "right" };

        // Refunds
        let targetRowForChargesRefundsValue = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForChargesRefundsValue}:D${targetRowForChargesRefundsValue}`
        );
        worksheet.getCell(
          `B${targetRowForChargesRefundsValue}:D${targetRowForChargesRefundsValue}`
        ).value = "Refunds:";
        worksheet.getCell(
          `B${targetRowForChargesRefundsValue}:D${targetRowForChargesRefundsValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForChargesRefundsValue}:D${targetRowForChargesRefundsValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // cost
        worksheet.getCell(`F${targetRowForChargesRefundsValue}`).value = `${
          sheets?.sheetdata?.charge_fees?.refunded_fees?.cost ?? ""
        }`;
        worksheet.getCell(`F${targetRowForChargesRefundsValue}`).font = {
          italic: true,
          color: { argb: templateColor?.blue },
        };
        worksheet.getCell(`F${targetRowForChargesRefundsValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // count
        worksheet.getCell(`G${targetRowForChargesRefundsValue}`).value = `${
          sheets?.sheetdata?.charge_fees?.refunded_fees?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForChargesRefundsValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`G${targetRowForChargesRefundsValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // amount
        worksheet.getCell(`H${targetRowForChargesRefundsValue}`).value = `${
          sheets?.sheetdata?.charge_fees?.refunded_fees?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.charge_fees?.refunded_fees?.amount
          )?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(`H${targetRowForChargesRefundsValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`H${targetRowForChargesRefundsValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // empty
        worksheet.getCell(`I${targetRowForChargesRefundsValue}`).value = "";
        worksheet.getCell(`I${targetRowForChargesRefundsValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`I${targetRowForChargesRefundsValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // Dispute Fee
        let targetRowForChargesDisputeFeeValue = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForChargesDisputeFeeValue}:D${targetRowForChargesDisputeFeeValue}`
        );
        worksheet.getCell(
          `B${targetRowForChargesDisputeFeeValue}:D${targetRowForChargesDisputeFeeValue}`
        ).value = "Dispute Fee:";
        worksheet.getCell(
          `B${targetRowForChargesDisputeFeeValue}:D${targetRowForChargesDisputeFeeValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForChargesDisputeFeeValue}:D${targetRowForChargesDisputeFeeValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `B${targetRowForChargesDisputeFeeValue}:D${targetRowForChargesDisputeFeeValue}`
        ).border = {
          bottom: { style: "thin", color: { argb: templateColor?.blue } },
        };
        worksheet.getCell(`E${targetRowForChargesDisputeFeeValue}`).border = {
          bottom: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // cost
        worksheet.getCell(`F${targetRowForChargesDisputeFeeValue}`).value = `${
          sheets?.sheetdata?.charge_fees?.dispute_fee?.cost ?? ""
        }`;
        worksheet.getCell(`F${targetRowForChargesDisputeFeeValue}`).font = {
          italic: true,
          color: { argb: templateColor?.blue },
        };
        worksheet.getCell(`F${targetRowForChargesDisputeFeeValue}`).alignment =
          { vertical: "top", horizontal: "right" };
        worksheet.getCell(`F${targetRowForChargesDisputeFeeValue}`).border = {
          bottom: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // count
        worksheet.getCell(`G${targetRowForChargesDisputeFeeValue}`).value = `${
          sheets?.sheetdata?.charge_fees?.dispute_fee?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForChargesDisputeFeeValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`G${targetRowForChargesDisputeFeeValue}`).alignment =
          { vertical: "top", horizontal: "right" };
        worksheet.getCell(`G${targetRowForChargesDisputeFeeValue}`).border = {
          bottom: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // amount
        worksheet.getCell(`H${targetRowForChargesDisputeFeeValue}`).value = `${
          sheets?.sheetdata?.charge_fees?.dispute_fee?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(sheets?.sheetdata?.charge_fees?.dispute_fee?.amount)?.toFixed(
            2
          ) ||
            "") ??
          ""
        }`;
        worksheet.getCell(`H${targetRowForChargesDisputeFeeValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`H${targetRowForChargesDisputeFeeValue}`).alignment =
          { vertical: "top", horizontal: "right" };
        worksheet.getCell(`H${targetRowForChargesDisputeFeeValue}`).border = {
          bottom: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // empty
        worksheet.getCell(`I${targetRowForChargesDisputeFeeValue}`).value = "";
        worksheet.getCell(`I${targetRowForChargesDisputeFeeValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`I${targetRowForChargesDisputeFeeValue}`).alignment =
          { vertical: "top", horizontal: "right" };
        worksheet.getCell(`I${targetRowForChargesDisputeFeeValue}`).border = {
          bottom: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // Total Charges
        let targetRowForChargesTotalChargesValue =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForChargesTotalChargesValue}:D${targetRowForChargesTotalChargesValue}`
        );
        worksheet.getCell(
          `B${targetRowForChargesTotalChargesValue}:D${targetRowForChargesTotalChargesValue}`
        ).value = "Total Charges:";
        worksheet.getCell(
          `B${targetRowForChargesTotalChargesValue}:D${targetRowForChargesTotalChargesValue}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForChargesTotalChargesValue}:D${targetRowForChargesTotalChargesValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `B${targetRowForChargesTotalChargesValue}:D${targetRowForChargesTotalChargesValue}`
        ).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };
        worksheet.getCell(`E${targetRowForChargesTotalChargesValue}`).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // cost
        worksheet.getCell(
          `F${targetRowForChargesTotalChargesValue}`
        ).value = `${
          sheets?.sheetdata?.charge_fees?.total_charges?.cost ?? ""
        }`;
        worksheet.getCell(`F${targetRowForChargesTotalChargesValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `F${targetRowForChargesTotalChargesValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`F${targetRowForChargesTotalChargesValue}`).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // count
        worksheet.getCell(
          `G${targetRowForChargesTotalChargesValue}`
        ).value = `${
          sheets?.sheetdata?.charge_fees?.total_charges?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForChargesTotalChargesValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `G${targetRowForChargesTotalChargesValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`G${targetRowForChargesTotalChargesValue}`).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // amount
        worksheet.getCell(
          `H${targetRowForChargesTotalChargesValue}`
        ).value = `${
          sheets?.sheetdata?.charge_fees?.total_charges?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.charge_fees?.total_charges?.amount
          )?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(`H${targetRowForChargesTotalChargesValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `H${targetRowForChargesTotalChargesValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`H${targetRowForChargesTotalChargesValue}`).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // empty
        worksheet.getCell(`I${targetRowForChargesTotalChargesValue}`).value =
          "";
        worksheet.getCell(`I${targetRowForChargesTotalChargesValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `I${targetRowForChargesTotalChargesValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`I${targetRowForChargesTotalChargesValue}`).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // Payout Before Net Rolling Reserve
        let targetRowForChargesPayoutBeforeNetRollingReserveValue =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForChargesPayoutBeforeNetRollingReserveValue}:D${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        );
        worksheet.getCell(
          `B${targetRowForChargesPayoutBeforeNetRollingReserveValue}:D${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).value = "Payout Before Net Rolling Reserve:";
        worksheet.getCell(
          `B${targetRowForChargesPayoutBeforeNetRollingReserveValue}:D${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForChargesPayoutBeforeNetRollingReserveValue}:D${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `B${targetRowForChargesPayoutBeforeNetRollingReserveValue}:D${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };
        worksheet.getCell(
          `E${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // cost
        worksheet.getCell(
          `F${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).value = `${
          sheets?.sheetdata?.charge_fees?.payout_before_net_rolling_reserve
            ?.cost ?? ""
        }`;
        worksheet.getCell(
          `F${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `F${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `F${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // count
        worksheet.getCell(
          `G${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).value = `${
          sheets?.sheetdata?.charge_fees?.payout_before_net_rolling_reserve
            ?.count ?? ""
        }`;
        worksheet.getCell(
          `G${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `G${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `G${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // amount
        worksheet.getCell(
          `H${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).value = `${
          sheets?.sheetdata?.charge_fees?.payout_before_net_rolling_reserve
            ?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.charge_fees?.payout_before_net_rolling_reserve
              ?.amount
          )?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(
          `H${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).font = { bold: true, color: { argb: templateColor?.yellow } };
        worksheet.getCell(
          `H${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `H${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // empty
        worksheet.getCell(
          `I${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).value = "";
        worksheet.getCell(
          `I${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `I${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `I${targetRowForChargesPayoutBeforeNetRollingReserveValue}`
        ).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // Add an empty row after the last entry
        worksheet.addRow([]);

        let targetRowForHeld = worksheet?.lastRow?.number + 1;

        // held
        worksheet.mergeCells(`B${targetRowForHeld}:D${targetRowForHeld}`);
        worksheet.getCell(`B${targetRowForHeld}:D${targetRowForHeld}`).value =
          "Held";
        worksheet.getCell(`B${targetRowForHeld}:D${targetRowForHeld}`).font = {
          bold: true,
          color: { argb: templateColor?.white },
        };
        worksheet.getCell(
          `B${targetRowForHeld}:D${targetRowForHeld}`
        ).alignment = { vertical: "top", horizontal: "center" };
        worksheet.getCell(`B${targetRowForHeld}:D${targetRowForHeld}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: templateColor?.blue },
        };

        let targetRowForHeldUpperBorder = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForHeldUpperBorder}:I${targetRowForHeldUpperBorder}`
        );
        worksheet.getCell(
          `B${targetRowForHeldUpperBorder}:I${targetRowForHeldUpperBorder}`
        ).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // Held Brought Forward
        let targetRowForHeldBroughtForwardValue =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForHeldBroughtForwardValue}:D${targetRowForHeldBroughtForwardValue}`
        );
        worksheet.getCell(
          `B${targetRowForHeldBroughtForwardValue}:D${targetRowForHeldBroughtForwardValue}`
        ).value = "HELD Brought Forward:";
        worksheet.getCell(
          `B${targetRowForHeldBroughtForwardValue}:D${targetRowForHeldBroughtForwardValue}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForHeldBroughtForwardValue}:D${targetRowForHeldBroughtForwardValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `B${targetRowForHeldBroughtForwardValue}:D${targetRowForHeldBroughtForwardValue}`
        ).border = {
          bottom: { style: "thin", color: { argb: templateColor?.blue } },
        };
        worksheet.getCell(`E${targetRowForHeldBroughtForwardValue}`).border = {
          bottom: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // cost
        worksheet.getCell(`F${targetRowForHeldBroughtForwardValue}`).value = `${
          sheets?.sheetdata?.held?.held_brought_forward?.cost ?? ""
        }`;
        worksheet.getCell(`F${targetRowForHeldBroughtForwardValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`F${targetRowForHeldBroughtForwardValue}`).alignment =
          { vertical: "top", horizontal: "right" };
        worksheet.getCell(`F${targetRowForHeldBroughtForwardValue}`).border = {
          bottom: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // count
        worksheet.getCell(`G${targetRowForHeldBroughtForwardValue}`).value = `${
          sheets?.sheetdata?.held?.held_brought_forward?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForHeldBroughtForwardValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`G${targetRowForHeldBroughtForwardValue}`).alignment =
          { vertical: "top", horizontal: "right" };
        worksheet.getCell(`G${targetRowForHeldBroughtForwardValue}`).border = {
          bottom: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // amount
        worksheet.getCell(`H${targetRowForHeldBroughtForwardValue}`).value = `${
          sheets?.sheetdata?.held?.held_brought_forward?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.held?.held_brought_forward?.amount
          )?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(`H${targetRowForHeldBroughtForwardValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`H${targetRowForHeldBroughtForwardValue}`).alignment =
          { vertical: "top", horizontal: "right" };
        worksheet.getCell(`H${targetRowForHeldBroughtForwardValue}`).border = {
          bottom: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // empty
        worksheet.getCell(`I${targetRowForHeldBroughtForwardValue}`).value = "";
        worksheet.getCell(`I${targetRowForHeldBroughtForwardValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`I${targetRowForHeldBroughtForwardValue}`).alignment =
          { vertical: "top", horizontal: "right" };
        worksheet.getCell(`I${targetRowForHeldBroughtForwardValue}`).border = {
          bottom: { style: "thin", color: { argb: templateColor?.blue } },
        };

        // Current Period Held
        let targetRowForCurrentPeriodHeldValue = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForCurrentPeriodHeldValue}:D${targetRowForCurrentPeriodHeldValue}`
        );
        worksheet.getCell(
          `B${targetRowForCurrentPeriodHeldValue}:D${targetRowForCurrentPeriodHeldValue}`
        ).value = "Current Period Held:";
        worksheet.getCell(
          `B${targetRowForCurrentPeriodHeldValue}:D${targetRowForCurrentPeriodHeldValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForCurrentPeriodHeldValue}:D${targetRowForCurrentPeriodHeldValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // cost
        worksheet.getCell(`F${targetRowForCurrentPeriodHeldValue}`).value = `${
          sheets?.sheetdata?.held?.current_period_held?.cost ?? ""
        }`;
        worksheet.getCell(`F${targetRowForCurrentPeriodHeldValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`F${targetRowForCurrentPeriodHeldValue}`).alignment =
          { vertical: "top", horizontal: "right" };

        // count
        worksheet.getCell(`G${targetRowForCurrentPeriodHeldValue}`).value = `${
          sheets?.sheetdata?.held?.current_period_held?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForCurrentPeriodHeldValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`G${targetRowForCurrentPeriodHeldValue}`).alignment =
          { vertical: "top", horizontal: "right" };

        // amount
        worksheet.getCell(`H${targetRowForCurrentPeriodHeldValue}`).value = `${
          sheets?.sheetdata?.held?.current_period_held?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.held?.current_period_held?.amount
          )?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(`H${targetRowForCurrentPeriodHeldValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`H${targetRowForCurrentPeriodHeldValue}`).alignment =
          { vertical: "top", horizontal: "right" };

        // empty
        worksheet.getCell(`I${targetRowForCurrentPeriodHeldValue}`).value = "";
        worksheet.getCell(`I${targetRowForCurrentPeriodHeldValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`I${targetRowForCurrentPeriodHeldValue}`).alignment =
          { vertical: "top", horizontal: "right" };

        // Current Period Held Approved
        let targetRowForCurrentPeriodHeldApprovedValue =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForCurrentPeriodHeldApprovedValue}:D${targetRowForCurrentPeriodHeldApprovedValue}`
        );
        worksheet.getCell(
          `B${targetRowForCurrentPeriodHeldApprovedValue}:D${targetRowForCurrentPeriodHeldApprovedValue}`
        ).value = "Current Period Held Approved:";
        worksheet.getCell(
          `B${targetRowForCurrentPeriodHeldApprovedValue}:D${targetRowForCurrentPeriodHeldApprovedValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForCurrentPeriodHeldApprovedValue}:D${targetRowForCurrentPeriodHeldApprovedValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // cost
        worksheet.getCell(
          `F${targetRowForCurrentPeriodHeldApprovedValue}`
        ).value = `${
          sheets?.sheetdata?.held?.current_period_held_approved?.cost ?? ""
        }`;
        worksheet.getCell(
          `F${targetRowForCurrentPeriodHeldApprovedValue}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `F${targetRowForCurrentPeriodHeldApprovedValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // count
        worksheet.getCell(
          `G${targetRowForCurrentPeriodHeldApprovedValue}`
        ).value = `${
          sheets?.sheetdata?.held?.current_period_held_approved?.count ?? ""
        }`;
        worksheet.getCell(
          `G${targetRowForCurrentPeriodHeldApprovedValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `G${targetRowForCurrentPeriodHeldApprovedValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // amount
        worksheet.getCell(
          `H${targetRowForCurrentPeriodHeldApprovedValue}`
        ).value = `${
          sheets?.sheetdata?.held?.current_period_held_approved?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.held?.current_period_held_approved?.amount
          )?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(
          `H${targetRowForCurrentPeriodHeldApprovedValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `H${targetRowForCurrentPeriodHeldApprovedValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // empty
        worksheet.getCell(
          `I${targetRowForCurrentPeriodHeldApprovedValue}`
        ).value = "";
        worksheet.getCell(
          `I${targetRowForCurrentPeriodHeldApprovedValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `I${targetRowForCurrentPeriodHeldApprovedValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // Current Period Reserve Refunded
        let targetRowForCurrentPeriodReserveRefundedValue =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForCurrentPeriodReserveRefundedValue}:D${targetRowForCurrentPeriodReserveRefundedValue}`
        );
        worksheet.getCell(
          `B${targetRowForCurrentPeriodReserveRefundedValue}:D${targetRowForCurrentPeriodReserveRefundedValue}`
        ).value = "Current Period Reserve Refunded:";
        worksheet.getCell(
          `B${targetRowForCurrentPeriodReserveRefundedValue}:D${targetRowForCurrentPeriodReserveRefundedValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForCurrentPeriodReserveRefundedValue}:D${targetRowForCurrentPeriodReserveRefundedValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // cost
        worksheet.getCell(
          `F${targetRowForCurrentPeriodReserveRefundedValue}`
        ).value = `${
          sheets?.sheetdata?.held?.current_period_refunded?.cost ?? ""
        }`;
        worksheet.getCell(
          `F${targetRowForCurrentPeriodReserveRefundedValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `F${targetRowForCurrentPeriodReserveRefundedValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // count
        worksheet.getCell(
          `G${targetRowForCurrentPeriodReserveRefundedValue}`
        ).value = `${
          sheets?.sheetdata?.held?.current_period_refunded?.count ?? ""
        }`;
        worksheet.getCell(
          `G${targetRowForCurrentPeriodReserveRefundedValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `G${targetRowForCurrentPeriodReserveRefundedValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // amount
        worksheet.getCell(
          `H${targetRowForCurrentPeriodReserveRefundedValue}`
        ).value = `${
          sheets?.sheetdata?.held?.current_period_refunded?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.held?.current_period_refunded?.amount
          )?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(
          `H${targetRowForCurrentPeriodReserveRefundedValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `H${targetRowForCurrentPeriodReserveRefundedValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // empty
        worksheet.getCell(
          `I${targetRowForCurrentPeriodReserveRefundedValue}`
        ).value = "";
        worksheet.getCell(
          `I${targetRowForCurrentPeriodReserveRefundedValue}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `I${targetRowForCurrentPeriodReserveRefundedValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // Total HELD
        let targetRowForTotalHeldValue = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForTotalHeldValue}:D${targetRowForTotalHeldValue}`
        );
        worksheet.getCell(
          `B${targetRowForTotalHeldValue}:D${targetRowForTotalHeldValue}`
        ).value = "Total Held:";
        worksheet.getCell(
          `B${targetRowForTotalHeldValue}:D${targetRowForTotalHeldValue}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForTotalHeldValue}:D${targetRowForTotalHeldValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `B${targetRowForTotalHeldValue}:D${targetRowForTotalHeldValue}`
        ).border = {
          top: { style: "thin", color: { argb: templateColor?.blue } },
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };
        worksheet.getCell(`E${targetRowForTotalHeldValue}`).border = {
          top: { style: "thin", color: { argb: templateColor?.blue } },
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // cost
        worksheet.getCell(`F${targetRowForTotalHeldValue}`).value = `${
          sheets?.sheetdata?.held?.total_held?.cost ?? ""
        }`;
        worksheet.getCell(`F${targetRowForTotalHeldValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`F${targetRowForTotalHeldValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };
        worksheet.getCell(`F${targetRowForTotalHeldValue}`).border = {
          top: { style: "thin", color: { argb: templateColor?.blue } },
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // count
        worksheet.getCell(`G${targetRowForTotalHeldValue}`).value = `${
          sheets?.sheetdata?.held?.total_held?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForTotalHeldValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`G${targetRowForTotalHeldValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };
        worksheet.getCell(`G${targetRowForTotalHeldValue}`).border = {
          top: { style: "thin", color: { argb: templateColor?.blue } },
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // amount
        worksheet.getCell(`H${targetRowForTotalHeldValue}`).value = `${
          sheets?.sheetdata?.held?.total_held?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(sheets?.sheetdata?.held?.total_held?.amount)?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(`H${targetRowForTotalHeldValue}`).font = {
          bold: true,
          color: { argb: templateColor?.yellow },
        };
        worksheet.getCell(`H${targetRowForTotalHeldValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };
        worksheet.getCell(`H${targetRowForTotalHeldValue}`).border = {
          top: { style: "thin", color: { argb: templateColor?.blue } },
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // empty
        worksheet.getCell(`I${targetRowForTotalHeldValue}`).value = "";
        worksheet.getCell(`I${targetRowForTotalHeldValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`I${targetRowForTotalHeldValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };
        worksheet.getCell(`I${targetRowForTotalHeldValue}`).border = {
          top: { style: "thin", color: { argb: templateColor?.blue } },
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // Add an empty row after the last entry
        worksheet.addRow([]);

        let targetRowForReserve = worksheet?.lastRow?.number + 1;

        // Reserve
        worksheet.mergeCells(`B${targetRowForReserve}:D${targetRowForReserve}`);
        worksheet.getCell(
          `B${targetRowForReserve}:D${targetRowForReserve}`
        ).value = "Reserve";
        worksheet.getCell(
          `B${targetRowForReserve}:D${targetRowForReserve}`
        ).font = { bold: true, color: { argb: templateColor?.white } };
        worksheet.getCell(
          `B${targetRowForReserve}:D${targetRowForReserve}`
        ).alignment = { vertical: "top", horizontal: "center" };
        worksheet.getCell(
          `B${targetRowForReserve}:D${targetRowForReserve}`
        ).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: templateColor?.blue },
        };

        let targetRowForReserveUpperBorder = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForReserveUpperBorder}:I${targetRowForReserveUpperBorder}`
        );
        worksheet.getCell(
          `B${targetRowForReserveUpperBorder}:I${targetRowForReserveUpperBorder}`
        ).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // Balance Brought Forward
        let targetRowForBalanceBroughtForwardValue =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForBalanceBroughtForwardValue}:D${targetRowForBalanceBroughtForwardValue}`
        );
        worksheet.getCell(
          `B${targetRowForBalanceBroughtForwardValue}:D${targetRowForBalanceBroughtForwardValue}`
        ).value = "Balance Brought Forward:";
        worksheet.getCell(
          `B${targetRowForBalanceBroughtForwardValue}:D${targetRowForBalanceBroughtForwardValue}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForBalanceBroughtForwardValue}:D${targetRowForBalanceBroughtForwardValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `B${targetRowForBalanceBroughtForwardValue}:D${targetRowForBalanceBroughtForwardValue}`
        ).border = {
          bottom: { style: "thin", color: { argb: templateColor?.blue } },
        };
        worksheet.getCell(`E${targetRowForBalanceBroughtForwardValue}`).border =
          {
            bottom: { style: "thin", color: { argb: templateColor?.blue } },
          };

        // cost
        worksheet.getCell(
          `F${targetRowForBalanceBroughtForwardValue}`
        ).value = `${
          sheets?.sheetdata?.reserved?.balance_brought_forward?.cost ?? ""
        }`;
        worksheet.getCell(`F${targetRowForBalanceBroughtForwardValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `F${targetRowForBalanceBroughtForwardValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`F${targetRowForBalanceBroughtForwardValue}`).border =
          {
            bottom: { style: "thin", color: { argb: templateColor?.blue } },
          };

        // count
        worksheet.getCell(
          `G${targetRowForBalanceBroughtForwardValue}`
        ).value = `${
          sheets?.sheetdata?.reserved?.balance_brought_forward?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForBalanceBroughtForwardValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `G${targetRowForBalanceBroughtForwardValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`G${targetRowForBalanceBroughtForwardValue}`).border =
          {
            bottom: { style: "thin", color: { argb: templateColor?.blue } },
          };

        // amount
        worksheet.getCell(
          `H${targetRowForBalanceBroughtForwardValue}`
        ).value = `${
          sheets?.sheetdata?.reserved?.balance_brought_forward?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.reserved?.balance_brought_forward?.amount
          )?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(`H${targetRowForBalanceBroughtForwardValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `H${targetRowForBalanceBroughtForwardValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`H${targetRowForBalanceBroughtForwardValue}`).border =
          {
            bottom: { style: "thin", color: { argb: templateColor?.blue } },
          };

        // empty
        worksheet.getCell(`I${targetRowForBalanceBroughtForwardValue}`).value =
          "";
        worksheet.getCell(`I${targetRowForBalanceBroughtForwardValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `I${targetRowForBalanceBroughtForwardValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`I${targetRowForBalanceBroughtForwardValue}`).border =
          {
            bottom: { style: "thin", color: { argb: templateColor?.blue } },
          };

        // Current Period Reserve Deduction
        let targetRowForCurrentPeriodReserveDeductionValue =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForCurrentPeriodReserveDeductionValue}:D${targetRowForCurrentPeriodReserveDeductionValue}`
        );
        worksheet.getCell(
          `B${targetRowForCurrentPeriodReserveDeductionValue}:D${targetRowForCurrentPeriodReserveDeductionValue}`
        ).value = "Current Period Reserve Deduction:";
        worksheet.getCell(
          `B${targetRowForCurrentPeriodReserveDeductionValue}:D${targetRowForCurrentPeriodReserveDeductionValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForCurrentPeriodReserveDeductionValue}:D${targetRowForCurrentPeriodReserveDeductionValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // cost
        worksheet.getCell(
          `F${targetRowForCurrentPeriodReserveDeductionValue}`
        ).value = `${
          sheets?.sheetdata?.reserved?.current_period_reserve_deduction?.cost ??
          ""
        }`;
        worksheet.getCell(
          `F${targetRowForCurrentPeriodReserveDeductionValue}`
        ).font = { italic: true, color: { argb: templateColor?.blue } };
        worksheet.getCell(
          `F${targetRowForCurrentPeriodReserveDeductionValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // count
        worksheet.getCell(
          `G${targetRowForCurrentPeriodReserveDeductionValue}`
        ).value = `${
          sheets?.sheetdata?.reserved?.current_period_reserve_deduction
            ?.count ?? ""
        }`;
        worksheet.getCell(
          `G${targetRowForCurrentPeriodReserveDeductionValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `G${targetRowForCurrentPeriodReserveDeductionValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // amount
        worksheet.getCell(
          `H${targetRowForCurrentPeriodReserveDeductionValue}`
        ).value = `${
          sheets?.sheetdata?.reserved?.current_period_reserve_deduction?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.reserved?.current_period_reserve_deduction
              ?.amount
          )?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(
          `H${targetRowForCurrentPeriodReserveDeductionValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `H${targetRowForCurrentPeriodReserveDeductionValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // empty
        worksheet.getCell(
          `I${targetRowForCurrentPeriodReserveDeductionValue}`
        ).value = "";
        worksheet.getCell(
          `I${targetRowForCurrentPeriodReserveDeductionValue}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `I${targetRowForCurrentPeriodReserveDeductionValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // Current Period Reserve Release
        let targetRowForCurrentPeriodReserveReleaseValue =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForCurrentPeriodReserveReleaseValue}:D${targetRowForCurrentPeriodReserveReleaseValue}`
        );
        worksheet.getCell(
          `B${targetRowForCurrentPeriodReserveReleaseValue}:D${targetRowForCurrentPeriodReserveReleaseValue}`
        ).value = "Current Period Reserve Release:";
        worksheet.getCell(
          `B${targetRowForCurrentPeriodReserveReleaseValue}:D${targetRowForCurrentPeriodReserveReleaseValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForCurrentPeriodReserveReleaseValue}:D${targetRowForCurrentPeriodReserveReleaseValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // cost
        worksheet.getCell(
          `F${targetRowForCurrentPeriodReserveReleaseValue}`
        ).value = `${
          sheets?.sheetdata?.reserved?.current_period_reserve_release?.cost ??
          ""
        }`;
        worksheet.getCell(
          `F${targetRowForCurrentPeriodReserveReleaseValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `F${targetRowForCurrentPeriodReserveReleaseValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // count
        worksheet.getCell(
          `G${targetRowForCurrentPeriodReserveReleaseValue}`
        ).value = `${
          sheets?.sheetdata?.reserved?.current_period_reserve_release?.count ??
          ""
        }`;
        worksheet.getCell(
          `G${targetRowForCurrentPeriodReserveReleaseValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `G${targetRowForCurrentPeriodReserveReleaseValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // amount
        worksheet.getCell(
          `H${targetRowForCurrentPeriodReserveReleaseValue}`
        ).value = `${
          sheets?.sheetdata?.reserved?.current_period_reserve_release?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.reserved?.current_period_reserve_release?.amount
          )?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(
          `H${targetRowForCurrentPeriodReserveReleaseValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `H${targetRowForCurrentPeriodReserveReleaseValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // empty
        worksheet.getCell(
          `I${targetRowForCurrentPeriodReserveReleaseValue}`
        ).value = "";
        worksheet.getCell(
          `I${targetRowForCurrentPeriodReserveReleaseValue}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `I${targetRowForCurrentPeriodReserveReleaseValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // NetRollingReserve
        let targetRowForNetRollingReserveValue = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForNetRollingReserveValue}:D${targetRowForNetRollingReserveValue}`
        );
        worksheet.getCell(
          `B${targetRowForNetRollingReserveValue}:D${targetRowForNetRollingReserveValue}`
        ).value = "Net Rolling Reserve:";
        worksheet.getCell(
          `B${targetRowForNetRollingReserveValue}:D${targetRowForNetRollingReserveValue}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForNetRollingReserveValue}:D${targetRowForNetRollingReserveValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // cost
        worksheet.getCell(`F${targetRowForNetRollingReserveValue}`).value = `${
          sheets?.sheetdata?.reserved?.net_rolling_reserve?.cost ?? ""
        }`;
        worksheet.getCell(`F${targetRowForNetRollingReserveValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`F${targetRowForNetRollingReserveValue}`).alignment =
          { vertical: "top", horizontal: "right" };

        // count
        worksheet.getCell(`G${targetRowForNetRollingReserveValue}`).value = `${
          sheets?.sheetdata?.reserved?.net_rolling_reserve?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForNetRollingReserveValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`G${targetRowForNetRollingReserveValue}`).alignment =
          { vertical: "top", horizontal: "right" };

        // amount
        worksheet.getCell(`H${targetRowForNetRollingReserveValue}`).value = `${
          sheets?.sheetdata?.reserved?.net_rolling_reserve?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.reserved?.net_rolling_reserve?.amount
          )?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(`H${targetRowForNetRollingReserveValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`H${targetRowForNetRollingReserveValue}`).alignment =
          { vertical: "top", horizontal: "right" };

        // empty
        worksheet.getCell(`I${targetRowForNetRollingReserveValue}`).value = "";
        worksheet.getCell(`I${targetRowForNetRollingReserveValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`I${targetRowForNetRollingReserveValue}`).alignment =
          { vertical: "top", horizontal: "right" };

        // Balance Carried Forward
        let targetRowForBalanceCarriedForwardValue =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForBalanceCarriedForwardValue}:D${targetRowForBalanceCarriedForwardValue}`
        );
        worksheet.getCell(
          `B${targetRowForBalanceCarriedForwardValue}:D${targetRowForBalanceCarriedForwardValue}`
        ).value = "Balance Carried Forward:";
        worksheet.getCell(
          `B${targetRowForBalanceCarriedForwardValue}:D${targetRowForBalanceCarriedForwardValue}`
        ).font = { bold: true, color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForBalanceCarriedForwardValue}:D${targetRowForBalanceCarriedForwardValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(
          `B${targetRowForBalanceCarriedForwardValue}:D${targetRowForBalanceCarriedForwardValue}`
        ).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };
        worksheet.getCell(`E${targetRowForBalanceCarriedForwardValue}`).border =
          {
            bottom: { style: "thick", color: { argb: templateColor?.blue } },
          };

        // cost
        worksheet.getCell(
          `F${targetRowForBalanceCarriedForwardValue}`
        ).value = `${
          sheets?.sheetdata?.reserved?.balance_carried_forward?.cost ?? ""
        }`;
        worksheet.getCell(`F${targetRowForBalanceCarriedForwardValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `F${targetRowForBalanceCarriedForwardValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`F${targetRowForBalanceCarriedForwardValue}`).border =
          {
            bottom: { style: "thick", color: { argb: templateColor?.blue } },
          };

        // count
        worksheet.getCell(
          `G${targetRowForBalanceCarriedForwardValue}`
        ).value = `${
          sheets?.sheetdata?.reserved?.balance_carried_forward?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForBalanceCarriedForwardValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `G${targetRowForBalanceCarriedForwardValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`G${targetRowForBalanceCarriedForwardValue}`).border =
          {
            bottom: { style: "thick", color: { argb: templateColor?.blue } },
          };

        // amount
        worksheet.getCell(
          `H${targetRowForBalanceCarriedForwardValue}`
        ).value = `${
          sheets?.sheetdata?.reserved?.balance_carried_forward?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(
            sheets?.sheetdata?.reserved?.balance_carried_forward?.amount
          )?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(`H${targetRowForBalanceCarriedForwardValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `H${targetRowForBalanceCarriedForwardValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`H${targetRowForBalanceCarriedForwardValue}`).border =
          {
            bottom: { style: "thick", color: { argb: templateColor?.blue } },
          };

        // empty
        worksheet.getCell(`I${targetRowForBalanceCarriedForwardValue}`).value =
          "";
        worksheet.getCell(`I${targetRowForBalanceCarriedForwardValue}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(
          `I${targetRowForBalanceCarriedForwardValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`I${targetRowForBalanceCarriedForwardValue}`).border =
          {
            bottom: { style: "thick", color: { argb: templateColor?.blue } },
          };

        let targetRowForNetPayLowerBorderNewRow =
          worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForNetPayLowerBorderNewRow}:I${targetRowForNetPayLowerBorderNewRow}`
        );
        worksheet.getCell(
          `B${targetRowForNetPayLowerBorderNewRow}:I${targetRowForNetPayLowerBorderNewRow}`
        ).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        let targetRowForNetPayOut = worksheet?.lastRow?.number + 1;

        // Net Pay Out
        worksheet.mergeCells(
          `B${targetRowForNetPayOut}:D${targetRowForNetPayOut}`
        );
        worksheet.getCell(
          `B${targetRowForNetPayOut}:D${targetRowForNetPayOut}`
        ).value = "Net Pay Out";
        worksheet.getCell(
          `B${targetRowForNetPayOut}:D${targetRowForNetPayOut}`
        ).font = { bold: true, color: { argb: templateColor?.white } };
        worksheet.getCell(
          `B${targetRowForNetPayOut}:D${targetRowForNetPayOut}`
        ).alignment = { vertical: "top", horizontal: "center" };
        worksheet.getCell(
          `B${targetRowForNetPayOut}:D${targetRowForNetPayOut}`
        ).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: templateColor?.blue },
        };
        worksheet.getCell(`E${targetRowForNetPayOut}`).border = {
          top: { style: "thick", color: { argb: templateColor?.blue } },
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        let targetRowForNetPayUpperBorder = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForNetPayUpperBorder}:I${targetRowForNetPayUpperBorder}`
        );
        worksheet.getCell(
          `B${targetRowForNetPayUpperBorder}:I${targetRowForNetPayUpperBorder}`
        ).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // cost
        worksheet.getCell(`F${targetRowForNetPayOut}`).value = `${
          sheets?.sheetdata?.net_pay_out?.cost ?? ""
        }`;
        worksheet.getCell(`F${targetRowForNetPayOut}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`F${targetRowForNetPayOut}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };
        worksheet.getCell(`F${targetRowForNetPayOut}`).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // count
        worksheet.getCell(`G${targetRowForNetPayOut}`).value = `${
          sheets?.sheetdata?.net_pay_out?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForNetPayOut}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`G${targetRowForNetPayOut}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };
        worksheet.getCell(`G${targetRowForNetPayOut}`).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // amount
        worksheet.getCell(`H${targetRowForNetPayOut}`).value = `${
          sheets?.sheetdata?.net_pay_out?.amount ? sheets.currency_symbol : ""
        }${
          (Number(sheets?.sheetdata?.net_pay_out?.amount)?.toFixed(2) || "") ??
          ""
        }`;
        worksheet.getCell(`H${targetRowForNetPayOut}`).font = {
          bold: true,
          color: { argb: templateColor?.yellow },
        };
        worksheet.getCell(`H${targetRowForNetPayOut}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };
        worksheet.getCell(`H${targetRowForNetPayOut}`).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // empty
        worksheet.getCell(`I${targetRowForNetPayOut}`).value = "";
        worksheet.getCell(`I${targetRowForNetPayOut}`).font = {
          bold: true,
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`I${targetRowForNetPayOut}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };
        worksheet.getCell(`I${targetRowForNetPayOut}`).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // USDT Forex
        let targetRowForUSDTForexValue = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForUSDTForexValue}:D${targetRowForUSDTForexValue}`
        );
        worksheet.getCell(
          `B${targetRowForUSDTForexValue}:D${targetRowForUSDTForexValue}`
        ).value = "USDT Forex:";
        worksheet.getCell(
          `B${targetRowForUSDTForexValue}:D${targetRowForUSDTForexValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForUSDTForexValue}:D${targetRowForUSDTForexValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // cost
        worksheet.getCell(`F${targetRowForUSDTForexValue}`).value = `${
          sheets?.sheetdata?.usdt_forex?.cost ?? ""
        }`;
        worksheet.getCell(`F${targetRowForUSDTForexValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`F${targetRowForUSDTForexValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // count
        worksheet.getCell(`G${targetRowForUSDTForexValue}`).value = `${
          sheets?.sheetdata?.usdt_forex?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForUSDTForexValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`G${targetRowForUSDTForexValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // amount
        worksheet.getCell(`H${targetRowForUSDTForexValue}`).value = `${
          sheets?.sheetdata?.usdt_forex?.amount ? sheets.currency_symbol : ""
        }${sheets?.sheetdata?.usdt_forex?.amount?.toFixed(2) ?? ""}`;
        worksheet.getCell(`H${targetRowForUSDTForexValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`H${targetRowForUSDTForexValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // empty
        worksheet.getCell(`I${targetRowForUSDTForexValue}`).value = "";
        worksheet.getCell(`I${targetRowForUSDTForexValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`I${targetRowForUSDTForexValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // Withdrawal Fee
        let targetRowForWithdrawalFeeValue = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForWithdrawalFeeValue}:D${targetRowForWithdrawalFeeValue}`
        );
        worksheet.getCell(
          `B${targetRowForWithdrawalFeeValue}:D${targetRowForWithdrawalFeeValue}`
        ).value = "Withdrawal Fee:";
        worksheet.getCell(
          `B${targetRowForWithdrawalFeeValue}:D${targetRowForWithdrawalFeeValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForWithdrawalFeeValue}:D${targetRowForWithdrawalFeeValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // cost
        worksheet.getCell(`F${targetRowForWithdrawalFeeValue}`).value = `${
          sheets?.sheetdata?.withdrawal_fee?.cost ?? ""
        }`;
        worksheet.getCell(`F${targetRowForWithdrawalFeeValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`F${targetRowForWithdrawalFeeValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // count
        worksheet.getCell(`G${targetRowForWithdrawalFeeValue}`).value = `${
          sheets?.sheetdata?.withdrawal_fee?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForWithdrawalFeeValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`G${targetRowForWithdrawalFeeValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // amount
        worksheet.getCell(`H${targetRowForWithdrawalFeeValue}`).value = `${
          sheets?.sheetdata?.withdrawal_fee?.amount
            ? sheets.currency_symbol
            : ""
        }${sheets?.sheetdata?.withdrawal_fee?.amount ?? ""}`;
        worksheet.getCell(`H${targetRowForWithdrawalFeeValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`H${targetRowForWithdrawalFeeValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // empty
        worksheet.getCell(`I${targetRowForWithdrawalFeeValue}`).value = "";
        worksheet.getCell(`I${targetRowForWithdrawalFeeValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`I${targetRowForWithdrawalFeeValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // Settlement Fee
        let targetRowForSettlementFeeValue = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForSettlementFeeValue}:D${targetRowForSettlementFeeValue}`
        );
        worksheet.getCell(
          `B${targetRowForSettlementFeeValue}:D${targetRowForSettlementFeeValue}`
        ).value = "Settlement Fee:";
        worksheet.getCell(
          `B${targetRowForSettlementFeeValue}:D${targetRowForSettlementFeeValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForSettlementFeeValue}:D${targetRowForSettlementFeeValue}`
        ).alignment = { vertical: "top", horizontal: "right" };

        // cost
        worksheet.getCell(`F${targetRowForSettlementFeeValue}`).value = `${
          sheets?.sheetdata?.settlement_fee?.cost ?? ""
        }`;
        worksheet.getCell(`F${targetRowForSettlementFeeValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`F${targetRowForSettlementFeeValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // count
        worksheet.getCell(`G${targetRowForSettlementFeeValue}`).value = `${
          sheets?.sheetdata?.settlement_fee?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForSettlementFeeValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`G${targetRowForSettlementFeeValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // amount
        worksheet.getCell(`H${targetRowForSettlementFeeValue}`).value = `${
          sheets?.sheetdata?.settlement_fee?.amount
            ? sheets.currency_symbol
            : ""
        }${
          (Number(sheets?.sheetdata?.settlement_fee?.amount)?.toFixed(2) ||
            "") ??
          ""
        }`;
        worksheet.getCell(`H${targetRowForSettlementFeeValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`H${targetRowForSettlementFeeValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // empty
        worksheet.getCell(`I${targetRowForSettlementFeeValue}`).value = "";
        worksheet.getCell(`I${targetRowForSettlementFeeValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`I${targetRowForSettlementFeeValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };

        // hash
        let targetRowForHashValue = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${targetRowForHashValue}:D${targetRowForHashValue}`
        );
        worksheet.getCell(
          `B${targetRowForHashValue}:D${targetRowForHashValue}`
        ).value = "Hash:";
        worksheet.getCell(
          `B${targetRowForHashValue}:D${targetRowForHashValue}`
        ).font = { color: { argb: templateColor?.black } };
        worksheet.getCell(
          `B${targetRowForHashValue}:D${targetRowForHashValue}`
        ).alignment = { vertical: "top", horizontal: "right" };
        worksheet.getCell(`B${targetRowForHashValue}`).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };
        worksheet.getCell(`E${targetRowForHashValue}`).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // cost
        worksheet.getCell(`F${targetRowForHashValue}`).value = `${
          sheets?.sheetdata?.hash?.cost ?? ""
        }`;
        worksheet.getCell(`F${targetRowForHashValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`F${targetRowForHashValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };
        worksheet.getCell(`F${targetRowForHashValue}`).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // count
        worksheet.getCell(`G${targetRowForHashValue}`).value = `${
          sheets?.sheetdata?.hash?.count ?? ""
        }`;
        worksheet.getCell(`G${targetRowForHashValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`G${targetRowForHashValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };
        worksheet.getCell(`G${targetRowForHashValue}`).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // amount
        worksheet.getCell(`H${targetRowForHashValue}`).value = `${
          sheets?.sheetdata?.hash?.amount ?? ""
        }`;
        worksheet.getCell(`H${targetRowForHashValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`H${targetRowForHashValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };
        worksheet.getCell(`H${targetRowForHashValue}`).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // empty
        worksheet.getCell(`I${targetRowForHashValue}`).value = "";
        worksheet.getCell(`I${targetRowForHashValue}`).font = {
          color: { argb: templateColor?.black },
        };
        worksheet.getCell(`I${targetRowForHashValue}`).alignment = {
          vertical: "top",
          horizontal: "right",
        };
        worksheet.getCell(`I${targetRowForHashValue}`).border = {
          bottom: { style: "thick", color: { argb: templateColor?.blue } },
        };

        // Add an empty row after the last entry
        worksheet.addRow([]);
        worksheet.addRow([]);

        let totalPayoutMinusFees = worksheet?.lastRow?.number + 1;

        worksheet.mergeCells(
          `B${totalPayoutMinusFees}:D${totalPayoutMinusFees}`
        );
        worksheet.getCell(
          `B${totalPayoutMinusFees}:D${totalPayoutMinusFees}`
        ).value = "Total Payout Minus Fees";
        worksheet.getCell(
          `B${totalPayoutMinusFees}:D${totalPayoutMinusFees}`
        ).font = { bold: true, color: { argb: templateColor?.white } };
        worksheet.getCell(
          `B${totalPayoutMinusFees}:D${totalPayoutMinusFees}`
        ).alignment = { vertical: "top", horizontal: "center" };
        worksheet.getCell(`B${totalPayoutMinusFees}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: templateColor?.blue },
        };
        worksheet.getCell(`E${totalPayoutMinusFees}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: templateColor?.blue },
        };
        worksheet.getCell(`F${totalPayoutMinusFees}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: templateColor?.blue },
        };
        worksheet.getCell(`G${totalPayoutMinusFees}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: templateColor?.blue },
        };
        //cost
        worksheet.getCell(`F${totalPayoutMinusFees}`).value = `${
          sheets?.sheetdata?.total_payout_minus_fees?.cost ?? ""
        }`;

        //count
        worksheet.getCell(`G${totalPayoutMinusFees}`).value = `${
          sheets?.sheetdata?.total_payout_minus_fees?.count ?? ""
        }`;

        // amount
        worksheet.getCell(`H${totalPayoutMinusFees}`).value = `${
          (Number(sheets?.sheetdata?.total_payout_minus_fees?.amount)?.toFixed(
            2
          ) ||
            "") ??
          "0.00"
        }`;
        worksheet.getCell(`H${totalPayoutMinusFees}`).font = {
          bold: true,
          color: { argb: templateColor?.white },
        };
        worksheet.getCell(`H${totalPayoutMinusFees}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: templateColor?.blue },
        };
        worksheet.getCell(`I${totalPayoutMinusFees}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: templateColor?.blue },
        };
      }
    });
  } catch (error) {
    // console.log("error in summary report", error)
  }
}
